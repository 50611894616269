import React, {useState} from 'react';
import styles from './OverviewPicker.module.css';
import Button from '@material-ui/core/Button';
import { ServicesWrapper, DateHolder } from '../EmailPicker/pickerContainer';
import DatePicker from "react-datepicker";
import { useAuth0 } from "@auth0/auth0-react";
import { NativeSelect } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


export const OverviewPicker = ( {onChangeD} ) => {
    const options = [
        {
            label: "Month",
            value: "Month",
            id: 1,
        },
        {
          label: "Day",
          value: "Day",
          id: 2,
        },
        {
          label: "Week",
          value: "Week",
          id: 3,
        },
        {
          label: "Year",
          value: "Year",
          id: 4,
        },
      ];
    const [viewBy, setViewBy]  = useState("Month")
    const [timeStart, setTimeStart] = useState("Last Week")
    const [timeEnd, setTimeEnd] = useState("Last Week")
    const [timeLength, setTimeLength] = useState("All Time")
    const { user, getAccessTokenSilently } = useAuth0();


    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const domain = "dev-ativ-05e.us.auth0.com";

    const handleDateChange = async (e) => {
        setTimeStart(e[0])
        setTimeEnd(e[1])
        setTimeLength(" ")
        const token = await getAccessTokenSilently({
            audience: `https://${domain}/api/v2/`,
            scope:'read:current_user',
        });
        const userid = user.sub
        const message = {token, userid, timeStart:e[0], timeEnd:e[1], viewBy, timeLength:" "}

        const response = await fetch("/chartResult", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(message)
        });
        
        if (response.ok) {
            const newInfo = await response.json();
            onChangeD(newInfo.results)
        }
    }
    const handleChange = async (e) => {
        e.persist()
        setViewBy(e.target.value)        
        const token = await getAccessTokenSilently({
            audience: `https://${domain}/api/v2/`,
            scope:'read:current_user',
        });
        const userid = user.sub
        const message = {token, userid, timeStart, timeEnd, viewBy:e.target.value, timeLength}
        console.log(message)
        const response = await fetch("/chartResult", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(message)
        });
        
        if (response.ok) {
            const newInfo = await response.json();
            onChangeD(newInfo.results)
        }
    
      }

    const useStyles = makeStyles((theme) => ({
        date: {
          height: 35,
          width: 225,
          fontSize: 14,
          backgroundColor: theme.palette.colors.drawer
        },
      }));
    const classes2 = useStyles()

    return(
        <ServicesWrapper>
            <DateHolder>
                <DatePicker
                    className={classes2.date}
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                        if(update[1]){
                        handleDateChange(update)
                        }
                        setDateRange(update);
                    }}
                    isClearable={true}
                    placeholderText = "Custom Date Range"
                />
            </DateHolder>
            <Button variant="contained" color="secondary" className={styles.butt}
                onClick ={async () => {  
                    const token = await getAccessTokenSilently({
                        audience: `https://${domain}/api/v2/`,
                        scope:'read:current_user',
                    });
                    const userid = user.sub
                    const message = {token, userid, timeStart, timeEnd, viewBy, timeLength:"MTD"}
                    setTimeLength('MTD')

                    const response = await fetch("/chartResult", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(message)
                    });
                    
                    if (response.ok) {
                        const newInfo = await response.json();
                        onChangeD(newInfo.results)
                    }
                }}
                >
                MTD
            </Button>
            <Button variant="contained" color="secondary" className={styles.butt}
                onClick ={async () => {  
                    const token = await getAccessTokenSilently({
                        audience: `https://${domain}/api/v2/`,
                        scope:'read:current_user',
                    });
                    const userid = user.sub
                    const message = {token, userid, timeStart, timeEnd, viewBy, timeLength:"1M"}
                    setTimeLength('1M')

                    const response = await fetch("/chartResult", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(message)
                    });
                    
                    if (response.ok) {
                        const newInfo = await response.json();
                        onChangeD(newInfo.results)
                    }
                }}
                >
                1M
            </Button>
            <Button variant="contained" color="secondary" className={styles.butt}
                onClick ={async () => {  
                    const token = await getAccessTokenSilently({
                        audience: `https://${domain}/api/v2/`,
                        scope:'read:current_user',
                    });
                    const userid = user.sub
                    const message = {token, userid, timeStart, timeEnd, viewBy, timeLength:"3M"}
                    setTimeLength('3M')

                    const response = await fetch("/chartResult", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(message)
                    });
                    
                    if (response.ok) {
                        const newInfo = await response.json();
                        onChangeD(newInfo.results)
                    }
                }}
                >
                3M
            </Button>
            <Button variant="contained" color="secondary" className={styles.butt}
                onClick ={async () => {  
                    const token = await getAccessTokenSilently({
                        audience: `https://${domain}/api/v2/`,
                        scope:'read:current_user',
                    });
                    const userid = user.sub
                    const message = {token, userid, timeStart, timeEnd, viewBy, timeLength:"6M"}
                    setTimeLength('6M')

                    const response = await fetch("/chartResult", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(message)
                    });
                    
                    if (response.ok) {
                        const newInfo = await response.json();
                        onChangeD(newInfo.results)
                    }
                }}
                >
                6M
            </Button>
            <Button variant="contained" color="secondary" className={styles.butt}
                onClick ={async () => {  
                    const token = await getAccessTokenSilently({
                        audience: `https://${domain}/api/v2/`,
                        scope:'read:current_user',
                    });
                    const userid = user.sub
                    const message = {token, userid, timeStart, timeEnd, viewBy, timeLength:"YTD"}
                    setTimeLength('YTD')

                    const response = await fetch("/chartResult", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(message)
                    });
                    
                    if (response.ok) {
                        const newInfo = await response.json();
                        onChangeD(newInfo.results)
                    }
                }}
                >
                YTD
            </Button>
            <Button variant="contained" color="secondary" className={styles.butt}
                onClick ={async () => {  
                    const token = await getAccessTokenSilently({
                        audience: `https://${domain}/api/v2/`,
                        scope:'read:current_user',
                    });
                    const userid = user.sub
                    const message = {token, userid, timeStart, timeEnd, viewBy, timeLength:"1Y"}
                    setTimeLength('1Y')

                    const response = await fetch("/chartResult", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(message)
                    });
                    
                    if (response.ok) {
                        const newInfo = await response.json();
                        onChangeD(newInfo.results)
                    }
                }}
                >
                1Y
            </Button>
            <Button variant="contained" color="secondary" className={styles.butt2}
                onClick ={async () => {  
                    const token = await getAccessTokenSilently({
                        audience: `https://${domain}/api/v2/`,
                        scope:'read:current_user',
                    });
                    const userid = user.sub
                    const message = {token, userid, timeStart, timeEnd, viewBy, timeLength:"All Time"}
                    setTimeLength('All Time')

                    const response = await fetch("/chartResult", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(message)
                    });
                    
                    if (response.ok) {
                        const newInfo = await response.json();
                        onChangeD(newInfo.results)
                    }
                }}
                >
                All Time
            </Button>
            <NativeSelect justify = "center" className={styles.drop} defaultValue = "" 
            onChange ={handleChange}>
                {options.map((option) => (
                    <option key={option.id} value={option.value}>{option.label}</option>
                ))}
            </NativeSelect>
        </ServicesWrapper>

        // <Grid container spacing={2} justify ="center">
        //     <Grid item = {TextField} xs={3} md={1} >
        //         <TextField placeholder = "Start Date" className={styles.menu} onChange ={e => {setTimeStart(e.target.value)}}
        //         InputProps={{
        //             style: {
        //                 color: "#f3f3f3"
        //             }}}
        //             defaultValue = "">
        //         </TextField>
        //     </Grid>
        //     <Grid item = {TextField} xs={3} md={1} >
        //         <TextField placeholder = "End Date" className={styles.menu} onChange ={e => {setTimeEnd(e.target.value)}}
        //         InputProps={{
        //             style: {
        //                 color: "#f3f3f3"
        //             }}}
        //             defaultValue = "">
        //         </TextField>
        //     </Grid>
        //     <Grid>
        //         <Button variant="contained" color = "primary" className={styles.butt}
        //             onClick ={async () => {  
                        
        //                 const message = {viewBy, timeStart, timeEnd, timeLength:" "}
                        
        //                 const response = await fetch("/chartQuery", {
        //                     method: "POST",
        //                     headers: {
        //                         "Content-Type": "application/json"
        //                     },
        //                     body: JSON.stringify(message)
        //                 });
                        
        //                 if (response.ok) {
        //                     const response2 = await fetch('/chartResult');
        //                     const newGet = await response2.json();
        //                     console.log("response worked!");
        //                     console.log(message)
        //                     onChangeD(newGet.results)
        //                 }
        //             }}>
        //             Go
        //         </Button>
        //     </Grid>
        //     <Grid>
        //         <Button variant="contained" color="primary" className={styles.butt}
        //             onClick ={async () => {  
                        
        //                 const message = {viewBy, timeStart, timeEnd, timeLength:"1W"}
                        
        //                 const response = await fetch("/chartQuery", {
        //                     method: "POST",
        //                     headers: {
        //                         "Content-Type": "application/json"
        //                     },
        //                     body: JSON.stringify(message)
        //                 });
                        
        //                 if (response.ok) {
        //                     const response2 = await fetch('/chartResult');
        //                     const newGet = await response2.json();
        //                     console.log("response worked!");
        //                     console.log(message)
        //                     onChangeD(newGet.results)
        //                 }
        //             }}>
        //             Last Week
        //         </Button>
        //     </Grid>
        //     <Grid>
        //         <Button variant="contained" color = "primary" className={styles.butt}
        //             onClick ={async () => {  
                        
        //                 const message = {viewBy, timeStart, timeEnd, timeLength:"1M"}
                        
        //                 const response = await fetch("/chartQuery", {
        //                     method: "POST",
        //                     headers: {
        //                         "Content-Type": "application/json"
        //                     },
        //                     body: JSON.stringify(message)
        //                 });
                        
        //                 if (response.ok) {
        //                     console.log(message)
        //                     const response2 = await fetch('/chartResult');
        //                     const newGet = await response2.json();
        //                     console.log("response worked!");
        //                     onChangeD(newGet.results)
        //                 }
        //             }}>
        //             Last Month
        //         </Button>
        //     </Grid>
        //     <Grid>
        //         <Button variant="contained" color="primary" className={styles.butt}
        //         onClick ={async () => {  
        //             const message = {viewBy, timeStart, timeEnd, timeLength:"6M"}
                        
        //                 const response = await fetch("/chartQuery", {
        //                     method: "POST",
        //                     headers: {
        //                         "Content-Type": "application/json"
        //                     },
        //                     body: JSON.stringify(message)
        //                 });
                        

        //                 if (response.ok) {
        //                     const response2 = await fetch('/chartResult');
        //                     const newGet = await response2.json();
        //                     console.log("response worked!");
        //                     console.log(message)
        //                     onChangeD(newGet.results)
        //                     //onChangeO(newOs.orders)
        //                 }
        //             }}>
        //             6 Months
        //         </Button>
        //     </Grid>
        //     <Grid>
        //         <Button variant="contained" color="primary" className={styles.butt}
        //             onClick ={async () => {  
        
        //                 const message = {viewBy, timeStart, timeEnd, timeLength:"1Y"}
                        
        //                 console.log(message)
        //                 const response = await fetch("/chartQuery", {
        //                     method: "POST",
        //                     headers: {
        //                         "Content-Type": "application/json"
        //                     },
        //                     body: JSON.stringify(message)
        //                 });
                        
        //                 if (response.ok) {
        //                     const response2 = await fetch('/chartResult');
        //                     const newGet = await response2.json();
        //                     console.log("response worked!");
        //                     console.log(message)
        //                     onChangeD(newGet.results)
        //                 }
        //             }}>
        //             1 Year
        //         </Button>
        //     </Grid>
        //     <Grid>
        //         <Button variant="contained" color="primary" className={styles.butt}
        //             onClick ={async () => {  
        
        //                 const message = {viewBy, timeStart, timeEnd, timeLength:"All Time"}
                        
        //                 console.log(message)
        //                 const response = await fetch("/chartQuery", {
        //                     method: "POST",
        //                     headers: {
        //                         "Content-Type": "application/json"
        //                     },
        //                     body: JSON.stringify(message)
        //                 });
                        
        //                 if (response.ok) {
        //                     const response2 = await fetch('/chartResult');
        //                     const newGet = await response2.json();
        //                     console.log("response worked!");
        //                     onChangeD(newGet.results)
        //                     console.log(newGet.results)
        //                     //onChangeD(newGet.queries)
        //                     //onChangeO(newOs.orders)
        //                 }
        //             }}>
        //             All Time
        //         </Button>
        //     </Grid>
        //     <Grid item = {FormControl} xs={4} md={2}>
        //         <FormControl className={styles.drop2}>
        //             <Select
        //                     labelId="Selling Platform"
        //                     id="Selling Platform"
        //                     value = {viewBy}
        //                     renderValue={(viewBy) => `View by ${viewBy}`}
        //                     onChange={e => {setViewBy(e.target.value)} }
        //                 >
        //                 <MenuItem value={"Day"}>Day</MenuItem>
        //                 <MenuItem value={"Month"}>Month</MenuItem>
        //                 <MenuItem value={"Week"}>Week</MenuItem>
        //                 <MenuItem value={"Year"}>Year</MenuItem>
        //             </Select>
        //         </FormControl>
        //     </Grid>
        // </Grid>
    )
}

export default OverviewPicker;