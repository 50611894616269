import React, {useEffect, useState} from 'react';
import {Grid, NativeSelect, TextField} from '@material-ui/core';
import styles from './OrderHubSettings.module.css';
import Button from '@material-ui/core/Button';
import { useAuth0 } from "@auth0/auth0-react";
import { SettingsWrapper } from './SettingsContainer';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Popover from '@material-ui/core/Popover';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';



const useStyles = makeStyles((theme) => ({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
    formControl: {
        minWidth: 150,
        marginRight: 25,
      },
  }));
    

export const Gmails = ({allowForward}) => {
    const classes = useStyles();
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const domain = "dev-ativ-05e.us.auth0.com";
    const [selUser,setSelUser] = useState('')
    const [inputValue1, setInputValue1] = useState("");
    const [inputValue2, setInputValue2] = useState("");
    const [open2, setOpen2] = useState(false);
    const [openEmail, setOpenEmail] = useState(false);
    const [emailDomain, setEmailDomain] = useState("");

    const handleClose = () => {
        setOpen2(false);};
    const handleCloseEmail = () => {
        setOpenEmail(false); 
        resetInputField()
    };
    const handleClickOpen = () => {
        setOpen2(true); };
    const handlePopoverOpen2= (event) => {
        setAnchorEl2(event.currentTarget); };
    const handlePopoverClose2 = () => {
        setAnchorEl2(null); };
    const handleClickOpenEmail = () => {
        setOpenEmail(true); };

    if (allowForward === undefined){
        allowForward = false   
    }
    const [canForward, setCanForward] = useState(allowForward)
    const handleChange = async (event) => {    
        const decision = event.target.checked
        const token = await getAccessTokenSilently({
            audience: `https://${domain}/api/v2/`,
            scope:'read:current_user',
        });
        const userid = user.sub
        const bearerT = 'Bearer ' + token
        const userURL = "https://dev-ativ-05e.us.auth0.com/api/v2/users/" +userid
    
        const response = await fetch(userURL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": bearerT,
            },
        });
        if (response.ok) {

            // const userInfo = await response.json();  
            const forwardFalse = '{"user_metadata": {"forwarded_emails": "' + decision.toString() + '"}}'
            const response2 = await fetch(userURL, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": bearerT,
                },
                body: forwardFalse
            });
            if (response2.ok){
                setCanForward(decision)
                console.log('updated settings')
            }
        } 
    };
    
    useEffect(() => { setCanForward(allowForward)}, [allowForward] )

    const [options, setOptions] = useState()
    const test = options ? (
        options.map((option) => {
            return ( 
                    <option value={option.username}>{option.username}</option>
                    )}
                )) : 'LOADING...';

    const handleUserInput1 = (e) => {
        setInputValue1(e.target.value);
        setUsername(e.target.value)};
    
    // Reset Input Field handler
    const resetInputField = () => {
    setInputValue1("");
    setInputValue2("");
    setUsername("");
    setPassword("");
    setEmailDomain("")
    };

    const handleUserInput2 = (e) => {
        setInputValue2(e.target.value);
        setPassword(e.target.value)
      };
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const open = Boolean(anchorEl);
    const open4 = Boolean(anchorEl2);


    const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
    setAnchorEl(null);
    };

    const { user, getAccessTokenSilently } = useAuth0();


    // const error = inputValue2 !== "a";
    const error = (() => {
        if (emailDomain.includes('Gmail') | emailDomain.includes('Yahoo') | emailDomain.includes('iCloud') |
        emailDomain.includes('Hotmail') | emailDomain.includes('Microsoft') | emailDomain.includes('Outlook')) {
            if (inputValue2.length !== 0 && inputValue2.length !== 16 && inputValue2.length !== 19 ) {
                return true
            }else{
                return false
            }
        } else{
            return false
        }
    })();


    return(
        <SettingsWrapper>
            <Button variant="contained" color="secondary" className={styles.butt3} 
                onClick={handleClickOpenEmail}>
                Add Email Address
            </Button>
            <div></div>
            <div></div>
            <div></div>
            <div></div>

            <NativeSelect justify = "center" className={styles.drop} defaultValue = "" 
                onChange ={e => {console.log(e.target.value)
                    setSelUser(e.target.value)}}
                onClick ={async () => {  
                    const token = await getAccessTokenSilently({
                        audience: `https://${domain}/api/v2/`,
                        scope:'read:current_user',
                    });
                    const userid = user.sub
                    const message = {token, userid}
                    console.log(message)
                    const response = await fetch("/listEmail", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(message)
                    });
                    if (response.ok) {
                        const temp = await response.json()                            
                        setOptions(temp.users)
                        setSelUser('All Emails')
                        console.log("response worked!")
                    }
                    
                }}>
                {test}
            </NativeSelect>

            <Button style={{backgroundColor: "#DC676C"}} variant="contained" color="primary" className={styles.butt2}
                onClick={handleClickOpen}>
                Delete Selected Email(s)
            </Button>

            <div></div>
            <div></div>
            <div></div>
            <FormControlLabel
                className={styles.forward}
                control={<Checkbox
                    checked={canForward}
                    onChange={handleChange}
                    name="forwardEmails"
                    color="secondary"/>}
                label="Search forwarded emails?"
            />    
            <InfoOutlinedIcon
                className = {styles.info}
                aria-owns={open4 ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen2}
                onMouseLeave={handlePopoverClose2}/>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                paper: classes.paper,
                }}
                open={open4}
                anchorEl={anchorEl2}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                onClose={handlePopoverClose2}
                disableRestoreFocus>
                <Typography>By default auttr will only search for emails </Typography>
                <Typography>that are from a supported site. By enabling</Typography>
                <Typography>this feature, auttr will search for emails that</Typography>
                <Typography>have matching keywords in the subject of </Typography>
                <Typography>the email.</Typography>

            </Popover>
            <div></div>
            <div></div>
            <div></div>

            <Button variant="contained" color="secondary" className={styles.butt3}
            onClick ={async () => {  
                const token = await getAccessTokenSilently({
                    audience: `https://${domain}/api/v2/`,
                    scope:'read:current_user',
                });
                const userid = user.sub
                const message = {token, userid}
                const response = await fetch("/resetSizes", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(message)
                });
                if (response.ok) {
                    console.log("response worked!");
                }

            }}>
            Reset Order Hub
        </Button>

        <Dialog open={open2} onClose={handleClose} aria-labelledby="form3">
            <DialogTitle id="form3">Delete Selected Email(s)</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Are you sure you want to permanently delete the selected email addresses?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="primary">
                Cancel
            </Button>
            <Button style={{backgroundColor: "#DC676C"}} 
                onClick ={async () => {  
                    const token = await getAccessTokenSilently({
                        audience: `https://${domain}/api/v2/`,
                        scope:'read:current_user',
                    });
                    const userid = user.sub
                    const message = {username:selUser, password:"", token, userid}
                    const response = await fetch("/deleteEmails", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(message)
                    });
                    if (response.ok) {
                        console.log("response worked!");
                    }
                    const message2 = {token, userid}
                    const response2 = await fetch("/listEmail", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(message2)
                    });
                    if (response2.ok) {
                        const temp = await response2.json()                            
                        setOptions(temp.users)
                        setSelUser('All Emails')
                        console.log("response worked!")
                    }
                    handleClose()

                }} >
                Yes, Delete
            </Button>
            </DialogActions>
        </Dialog>

        <Dialog open={openEmail} maxWidth='md' onClose={handleCloseEmail} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Enter New Email Address</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Add an email address. Make sure to use an APP PASSWORD if you are not using a catchall (This is different from your regular password).
                </DialogContentText>
                <Grid container spacing={1} justify ="center">
                    <FormControl className={classes.formControl}>
                        <InputLabel id="Domain">Domain</InputLabel>
                        <Select
                            labelId="Domain"
                            id="Domain"
                            value={emailDomain}
                            defaultValue = ""
                            onChange={e => {setEmailDomain(e.target.value)}}
                        >
                        <MenuItem value={"Gmail"}>Gmail</MenuItem>
                        <MenuItem value={"Yahoo"}>Yahoo</MenuItem>
                        <MenuItem value={"iCloud"}>iCloud</MenuItem>
                        <MenuItem value={"Hotmail"}>Hotmail</MenuItem>
                        <MenuItem value={"Microsoft"}>Microsoft</MenuItem>
                        <MenuItem value={"Outlook"}>Outlook</MenuItem>
                        <MenuItem value={"Ionos"}>Ionos</MenuItem>

                        </Select>
                    </FormControl>
                    <TextField label = {"Email Address"} value = {inputValue1} className={styles.menu} onChange ={handleUserInput1}
                        InputProps={{
                            style: {
                                color: "#f3f3f3"
                            }}}
                            defaultValue = "">
                    </TextField>
                    <TextField label = {error ? "Invalid App Password" : "App Password"} value = {inputValue2} className={styles.menu} onChange ={handleUserInput2}
                        InputProps={{
                        style: {
                            color: "#f3f3f3"
                        },
                        endAdornment: (
                            <InputAdornment position="start">
                                <InfoOutlinedIcon
                                    // className = {styles.info}
                                    style={{ color: "#F1E0C5" }}
                                    aria-owns={open ? 'mouse-over-popover' : undefined}
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose}/>
                            </InputAdornment>
                        ), }}
                        defaultValue = ""
                        error = {error}>
                    </TextField>
                    <Popover
                        id="mouse-over-popover"
                        className={classes.popover}
                        classes={{
                        paper: classes.paper,}}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',}}
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',}}
                        onClose={handlePopoverClose}
                        disableRestoreFocus>
                        <Typography>The password for an email address with a gmail, </Typography>
                        <Typography>yahoo, icloud, outlook, microsoft, or hotmail </Typography>
                        <Typography>domain should be an "App Password". This is</Typography>
                        <Typography>different than your regular mail password.</Typography>
                        <Typography>Instructions for generating this password can be</Typography>
                        <Typography>found in the Discord support channel </Typography>
                    </Popover>
                </Grid>
                {/* <Grid container spacing={1} justify ="center">
                    <NativeSelect justify = "center" className={styles.drop2} defaultValue = "" 
                        onChange ={e => {console.log(e.target.value)
                            setSelUser(e.target.value)}}
                        onClick ={async () => {  
                            const token = await getAccessTokenSilently({
                                audience: `https://${domain}/api/v2/`,
                                scope:'read:current_user',
                            });
                            const userid = user.sub
                            const message = {token, userid, username: username, password: password, emailDomain: emailDomain}
                            if (username !== "" & password !== "" & emailDomain !== "") {
                                const response = await fetch("/listFolders", {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json"
                                    },
                                    body: JSON.stringify(message)
                                });
                                if (response.ok) {
                                    const temp = await response.json()                            
                                    console.log(temp)
                                    console.log("response worked!")
                                }                            
                            }

                        }}>
                        {test}
                    </NativeSelect>
                </Grid> */}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseEmail} color="primary">
                    Cancel
                </Button>
                <Button color="primary" 
                    onClick ={async () => {  
                            const token = await getAccessTokenSilently({
                                audience: `https://${domain}/api/v2/`,
                                scope:'read:current_user',
                            });
                            const userid = user.sub
                            const message = {token, userid, emailDomain:emailDomain, username:username, password:password}
                            if (!error & message.username.includes('@') & message.emailDomain !== "") {
                                const responseA = await fetch("/addEmail", {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json"
                                    },
                                    body: JSON.stringify(message)
                                });

                                if (responseA.ok) {
                                console.log("response worked!");
                                console.log(message)
                                setEmailDomain("")
                                resetInputField()

                                handleCloseEmail()
                            }
                            }
                        }} 
                  >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

            
 
    </SettingsWrapper>

    )
}

export default Gmails;