import styled from "styled-components";

export const ServicesWrapper = styled.div`
  max-width: 1500px;
  margin: 20px auto;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 2fr 2fr;
  align-items: center;
  grid-gap: 30px;
  //padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    align: left;
    max-width: 700px;
    grid-template-columns: 1fr 1fr;
    /* padding: 0 20px; */
  }
`;

export const DateHolder = styled.div`
  align-items: left;
`;
