import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import FilterListIcon from '@material-ui/icons/FilterList';
import StickyTable from "react-sticky-table-thead"
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { useAuth0 } from "@auth0/auth0-react";



function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'date', numeric: false, disablePadding: true, label: 'Date' },
    { id: 'name', numeric: true, disablePadding: false, label: 'Name' },
    { id: 'size', numeric: true, disablePadding: false, label: 'Size' },
    { id: 'cost', numeric: true, disablePadding: false, label: 'Cost ($)' },
    { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
    { id: 'sellPrice', numeric: false, disablePadding: true, label: 'Sell Price ($)' },
    { id: 'profit', numeric: true, disablePadding: false, label: 'Profit ($)' },
    { id: 'website', numeric: true, disablePadding: false, label: 'Site' },
  ];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor:theme.palette.background.paper,
      color: theme.palette.text.primary,
      position: 'sticky',
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            color="primary"
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:{
    color: "#1c1d1f",
    backgroundColor: "#F1E0C5",
  },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, open, setOpen } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    open,
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          All Purchases
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <EditIcon onClick={handleClickOpen} style={{fill: "#1c1d1f"}}/>
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    width: '100%',
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  row: {
    fontSize: 14,
  },
  row2:{
    fontSize: 14,
    color: theme.palette.colors.hyperlink,
    textDecorationLine: 'underline',
  }
}));

export default function SaleOverview({sales,onChangeS}) {
  const rows = sales
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { user, getAccessTokenSilently } = useAuth0();
  const domain = "dev-ativ-05e.us.auth0.com";



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const numEntries = rows.length

  const StyledTableRow = withStyles((theme) => ({
    root: {
        width:'100%',
      '&:nth-of-type(odd)': {
        backgroundColor:theme.palette.background.paper,
      },
    },
  }))(TableRow);

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const [open, setOpen] = React.useState(false);
  const [salePrice, setSalePrice] = useState("");
  const [sellingPlatform, setSellingPlatform] = useState("");
  const [percent, setPercent] = useState("");


  const handleClose = () => {
    setOpen(false);
    setSalePrice("");
    setSellingPlatform("");
    setPercent("");
  };

  const handleTrack = (tNumber) => {
    if (tNumber !== '' && tNumber !=='None') {
    return (event) => {
      window.open(tNumber, "_blank")
    }}
  }


  if (sales.length===0) {
    console.log('Loading...')
  }
  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Enter Sales Data</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter all information for a sale. This information will be saved for all selected items. 
          </DialogContentText>
          <Grid container spacing={1} justify ="space-between">
          <TextField
              autoFocus
              margin="dense"
              id="SalePrice"
              label="Sale Price"
              onChange ={e => {setSalePrice(e.target.value)}}
          />

          <FormControl className={classes.formControl}>
            <InputLabel id="Selling Platform">Selling Platform</InputLabel>
              <Select
                labelId="Selling Platform"
                id="Selling Platform"
                value={sellingPlatform}
                onChange={e => {setSellingPlatform(e.target.value)}}
              >
              <MenuItem value={"GOAT"}>GOAT</MenuItem>
              <MenuItem value={"StockX"}>StockX</MenuItem>
              <MenuItem value={"eBay"}>eBay</MenuItem>
              <MenuItem value={"Grailed"}>Grailed</MenuItem>
              <MenuItem value={"Local"}>Local</MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
              </Select>
          </FormControl>
          <TextField
              autoFocus
              margin="dense"
              id="PercentToGive"
              label="Fee (%)"
              onChange ={e => {setPercent(e.target.value)}}
          />
           </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick ={async () => {  
                const token = await getAccessTokenSilently({
                    audience: `https://${domain}/api/v2/`,
                    scope:'read:current_user',
                });
                const userid = user.sub
                const tempMes = selected
                var i;
                var textM = ""
                for (i = 0; i < tempMes.length; i++) {
                textM += tempMes[i].toString() + ",";
                }
                const message = {token, userid, ids: textM}
                console.log(message)
                const responseA = await fetch("/markUnsold", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(message)
                });

                if (responseA.ok) {
                    console.log("response worked!");
                    const tokmessage = {token, userid}

                    const response3 = await fetch("/sales", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(tokmessage)
                      });   
                    const newSales = await response3.json();
                    onChangeS(newSales.sales)
                    setSelected([])
                    console.log("refreshed")
                    handleClose()
                  }
                  }} >
            Mark Unsold
          </Button>
          <div style={{flex: '1 0 0'}} />

          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button color="primary" onClick ={async () => {  
                  const token = await getAccessTokenSilently({
                    audience: `https://${domain}/api/v2/`,
                    scope:'read:current_user',
                });
                const userid = user.sub
                const tempMes = selected
                var i;
                var textM = ""
                for (i = 0; i < tempMes.length; i++) {
                textM += tempMes[i].toString() + ",";
                }
                const message = {token, userid, ids: textM, salePrice:salePrice, sellingPlatform:sellingPlatform,percent:percent}
                const responseA = await fetch("/updateSale", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(message)
                });
                setSellingPlatform("")
                setSalePrice("")
                setPercent("")
                if (responseA.ok) {
                    console.log("response worked!");
                    console.log(textM)
                    const tokmessage = {token, userid}

                    const response3 = await fetch("/sales", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(tokmessage)
                      });   
                    const newSales = await response3.json();
                    onChangeS(newSales.sales)
                    setSelected([])
                    console.log("refreshed")
                    handleClose()
                  }}} >
            Submit
          </Button>
        </DialogActions>
      </Dialog>


        <EnhancedTableToolbar numSelected={selected.length} open = {open} setOpen = {setOpen}/>
        <TableContainer>
        <StickyTable height={400}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <StyledTableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </StyledTableCell>
                      <StyledTableCell component="th" id={labelId} scope="row" padding="none">
                        {row.date}
                      </StyledTableCell>
                      <StyledTableCell align="right" className={(row.orderInfo === 'None') ? classes.row : classes.row2} onClick={handleTrack(row.orderInfo)}>{row.name}</StyledTableCell>
                      <StyledTableCell align="right">{row.size}</StyledTableCell>
                      <StyledTableCell align="right">{row.cost}</StyledTableCell>
                      <StyledTableCell align="right">{row.status}</StyledTableCell>
                      <StyledTableCell align="right">{row.sellPrice}</StyledTableCell>
                      <StyledTableCell align="right">{row.profit}</StyledTableCell>
                      <StyledTableCell align="right">{row.website}</StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          </StickyTable>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, numEntries]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    </div>
  );
}
