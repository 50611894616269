// src/views/settings.js

import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import { OrderHubSettings } from '../Component';
import Divider from '@material-ui/core/Divider';
import { useAuth0 } from "@auth0/auth0-react";
import { MiscSettings } from "../Component/MiscSettings/MiscSettings";
import { DangerSettings} from "../Component/DangerSettings/DangerSettings";
import {CustomDrawer} from "../Component/CustomDrawer"


const Settings = props => {
  const { user, getAccessTokenSilently } = useAuth0();
  const BetaRole = user['http://user.com/roles'][0] === 'Beta User'
  const domain = "dev-ativ-05e.us.auth0.com";
  const [betaUser, setBetaUser] = useState(false)


  const [allowForward, setAllowForward] = useState()
  const [currWebhook, setCurrWebhook] = useState()

  useEffect(() => {
      const getUserMetadata = async () => {
      
      const token = await getAccessTokenSilently({
          audience: `https://${domain}/api/v2/`,
          scope:'read:current_user',
      });
      const userid = user.sub
      const bearerT = 'Bearer ' + token
      const userURL = "https://dev-ativ-05e.us.auth0.com/api/v2/users/" +userid

      const response = await fetch(userURL, {
          method: "GET",
          headers: {
              "Content-Type": "application/json",
              "Authorization": bearerT,
          },
      });

      if (response.ok) {
      const userInfo = await response.json();
          if (typeof userInfo.user_metadata !== 'undefined'){
            if (typeof userInfo.user_metadata.forwarded_emails === 'undefined'){
                const forwardFalse = '{"user_metadata": {"forwarded_emails": "false"}}'
                const response2 = await fetch(userURL, {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": bearerT,
                    },
                    body: forwardFalse
                });
                
                if (response2.ok) {
                  console.log('Changed forwarded email status')
                }
                
                
                setAllowForward(false)
            } else {
                const forwardString = userInfo.user_metadata.forwarded_emails
                if (forwardString === 'false'){
                    setAllowForward(false)
                } else {
                    setAllowForward(true)
                }
            }
          

            if (!userInfo.user_metadata.webhook){
              const webhookExist = '{"user_metadata": {"webhook": ""}}'
              const response2 = await fetch(userURL, {
                  method: "PATCH",
                  headers: {
                      "Content-Type": "application/json",
                      "Authorization": bearerT,
                  },
                  body: webhookExist
              });
              if (response2.ok){
                console.log('set Webhook')
              }
              setCurrWebhook("")
            } else {
                const webhookExist = userInfo.user_metadata.webhook
                setCurrWebhook(webhookExist)
            }
          }
      }
      }; 
      getUserMetadata();
  }, [getAccessTokenSilently, user]);

  useEffect(() => {
    const getUserMetadata = async () => {
      if (!BetaRole){
        const message = user.sub
        const response = await fetch("/checkForBeta", {
          method: "POST",
          headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify(message)
        });

        if (response.ok) {
          const isBeta = await response.json();
          setBetaUser(isBeta.isBeta)
        }
      }
      }; 
    getUserMetadata();
  }, [BetaRole, user]);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }));
  
  const classes = useStyles();

  const { history } = props;


const display = (betaUser | BetaRole) ?             
<Container maxWidth="lg" className={classes.container}>
  <Typography variant="h6">Order Hub Settings</Typography>
  <Divider />
  <OrderHubSettings allowForward={allowForward}/> 
  <Typography variant="h6"style={{ marginTop: 50 }}>General Settings</Typography>
  <Divider />
  <MiscSettings allowForward={allowForward} currWebhook={currWebhook} />
  <Typography variant="h6"style={{ marginTop: 50 }}>Danger Zone!</Typography>
  <Divider />
  <DangerSettings />
</Container>:
<Typography></Typography>

  return (
    <div className={classes.root}>
      {/* <CustomDrawer props={props}/> */}
        <main className={classes.content}>
          <div className={classes.toolbar} />
            {display}

        </main>
    </div>
  )
};

export default Settings;