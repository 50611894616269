import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import CountUp from 'react-countup';
import ShoppingCartTwoToneIcon from '@material-ui/icons/ShoppingCartTwoTone';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import LocalShippingTwoToneIcon from '@material-ui/icons/LocalShippingTwoTone';
import EqualizerTwoToneIcon from '@material-ui/icons/EqualizerTwoTone';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import { ServicesWrapper, ServicesCard,Line } from './CardsContent';

export const Cards = ( { queries } ) => {
    if (!queries.cancels) {
        queries.cancels = 1;
        queries.shipments = 1;
        queries.orders = 1;
        queries.percentage = 0;
        queries.total = 0;
    }
    return(
            <ServicesWrapper>
                <ServicesCard
                    onClick ={async () => {  
                        // const token = await getAccessTokenSilently({
                        //     audience: `https://${domain}/api/v2/`,
                        //     scope:'read:current_user',
                        // });
                        // const userid = user.sub
                        // const message = {token, userid, site, timeStart, timeEnd, timeLength:"1D"}
                        // setTimeLength('1D')

                        // const response = await fetch("/getQuery", {
                        //     method: "POST",
                        //     headers: {
                        //         "Content-Type": "application/json"
                        //     },
                        //     body: JSON.stringify(message)
                        // });
                        
                        // if (response.ok) {
                        //     const newInfo = await response.json();
                        //     const newOrders = newInfo.allInfo.allOrders
                        //     const newQueries = newInfo.allInfo.queries
                        //     onChangeO(newOrders)
                        //     onChangeD(newQueries)
                        // }
                    }}>
                    <Grid container spacing = {0} justifyContent = "space-between">
                        <Typography align='left'>Orders</Typography>
                        <ShoppingCartTwoToneIcon style={{ color: "#8b8e96" }}/>
                    </Grid>
                    <Line/>

                    <Typography variant="h3" gutterBottom={true} align = 'center'>
                        <CountUp start = {0} end = {queries.orders-1} duration = {2.5} separator=","/>
                    </Typography>                        
                    <Typography color="textSecondary" variant = "body2" align='center'>Total orders this period</Typography>
                </ServicesCard>
                <ServicesCard >
                    <Grid container spacing = {0} justifyContent = "space-between">
                        <Typography align='left'>Cancels</Typography>
                        <CancelTwoToneIcon style={{ color: "#8b8e96" }}/>
                    </Grid>
                    <Line/>
                    <Typography align = 'center' variant="h3" gutterBottom={true}>
                        <CountUp start = {0} end = {queries.cancels-1} duration = {2.5} separator=","/>
                    </Typography>
                        <Typography color="textSecondary" variant = "body2" align='center'>Total cancels this period</Typography>
                </ServicesCard>
                <ServicesCard>
                    <Grid container spacing = {0} justifyContent = "space-between">
                        <Typography align='left'>Shipments</Typography>
                        <LocalShippingTwoToneIcon style={{ color: "#8b8e96" }}/>
                    </Grid>
                    <Line/>

                    <Typography  align = 'center' variant="h3" gutterBottom={true}>
                        <CountUp start = {0} end = {queries.shipments-1} duration = {2.5} separator=","/>
                    </Typography>
                    <Typography color="textSecondary" variant = "body2" align='center'>Total shipments this period</Typography>
                </ServicesCard>
                <ServicesCard>
                    <Grid container spacing = {0} justifyContent = "space-between">
                        <Typography align='left'>Total Spent</Typography>
                        <AccountBalanceTwoToneIcon style={{ color: "#8b8e96" }}/>
                    </Grid>
                    <Line/>
                    <Typography variant="h5" gutterBottom={true} align='center'>
                        <CountUp start = {0} end = {queries.total} prefix = "$" duration = {2.5} separator=","/>
                    </Typography>

                    <Grid container spacing = {0} justifyContent = "space-between">
                        <Typography align='left'>Shipment %</Typography>
                        <EqualizerTwoToneIcon style={{ color: "#8b8e96" }}/>
                    </Grid>
                    <Line/>
                    <Typography variant="h5" gutterBottom={true} align='center'>
                        <CountUp start = {0} end = {queries.percentage} suffix = "%" duration = {2.5} separator=","/>
                    </Typography>


                </ServicesCard>
            </ServicesWrapper>
    )
}

export default Cards;