import styled from "styled-components";

export const DangerWrapper = styled.div`
  max-width: 1000px;
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 25px;
  justify-content: start;
`;
