import React, {useEffect, useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { NavBar } from "../Component/NavBar";
import {
    Drawer as MUIDrawer,
    ListItem,
    List,
    ListItemText} from "@material-ui/core";
import SettingsIcon from '@material-ui/icons/Settings';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import EmailIcon from '@material-ui/icons/Email';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Toggle from "./toggle";
import styles from "../App.css";
import { withRouter } from "react-router-dom";
import NightsStayIcon from '@mui/icons-material/NightsStay';
import LightModeIcon from '@mui/icons-material/LightMode';
import { setTheme } from "./theme";

const CustomDrawer = (props) => {    

    const [open, setOpen] = useState(false)
    const [lock, setLock] = useState(false)
    const drawerWidth = 200;
    const [active, setActive] = useState(false);
    const [ariaActive, setAriaActive] = useState(true);

    const changeThemeAndToggle = () => {
      if (localStorage.getItem("theme") === "theme-dark") {
        setTheme("theme-light");
        setActive(true);
        setAriaActive(false);
      } else {
        setTheme("theme-dark");
        setActive(false);
        setAriaActive(true);
      }
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDark = () => {
      props.settoggleDark(!props.toggleDark);
      changeThemeAndToggle();

    };

    const handleDrawerClose = () => {
        if (!lock){
            setOpen(false);
        }
    };
    const lockDrawer = () => {
        setLock(!lock);
    };
    const { history } = props;
    const itemsList = [
        {text: "Overview",
        icon: <AssignmentIcon />,
        onClick: () => history.push("/Overview")},
        { text: "Order Hub",
        icon: <EmailIcon />,
        onClick: () => history.push("/OrderHub")},
        {text: "Inventory",
        icon: <LayersIcon />,
        onClick: () => history.push("/Inventory")},
        {text: "Sales",
        icon: <AttachMoneyIcon />,
        onClick: () => history.push("/Sales")},
        {text: "Expenses",
        icon: <CreditCardIcon />,
        onClick: () => history.push("/Expenses")},
        {text: "Settings",
        icon: <SettingsIcon />,
        onClick: () => history.push("/Settings")}];

    const useStyles = makeStyles((theme) => ({
        root: {
          display: 'flex',
        },
        appBar: {
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: theme.palette.colors.drawer,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        },
        appBarShift: {
          marginLeft: drawerWidth,
          width: `calc(100% - ${drawerWidth}px)`,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
        menuButton: {
          marginRight: 36,
          color:theme.palette.text.primary,
        },
        hide: {
          display: 'none',
        },
        drawer: {
          width: drawerWidth,
          flexShrink: 0,
          whiteSpace: 'nowrap',
        },
        drawerOpen: {
          width: drawerWidth,
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          backgroundColor: theme.palette.colors.drawer,
    
        },
        drawerClose: {
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          backgroundColor: theme.palette.colors.drawer,
    
          overflowX: 'hidden',
          width: theme.spacing(7) + 1,
          [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
          },
        },
        toolbar: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          padding: theme.spacing(0, 1),
          // necessary for content to be below app bar
          ...theme.mixins.toolbar,
        },
        content: {
          flexGrow: 1,
          padding: theme.spacing(3),
        },
        paper: {
          padding: theme.spacing(2),
          display: 'flex',
          overflow: 'auto',
          flexDirection: 'column',
      },
      title: {
        flexGrow: 1,
        color: theme.palette.text.primary,
    },
    darkMode:{
      marginRight: 10,
      // backgroundColor: theme.palette.colors.drawer,
      // "&:hover": {
      //   backgroundColor: theme.palette.colors.drawer
      // }
    },

      }));
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar 
            position="fixed"
                className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
                })}
                >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                        [classes.hide]: open,
                        })}
                    >
                    <ChevronRightIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        auttr
                    </Typography>
                    <IconButton className={classes.darkMode} onClick={handleDark}>
                      {(props.toggleDark) ? <NightsStayIcon /> : <LightModeIcon />}
                    </IconButton>
                    
                    <NavBar />
                </Toolbar>
            </AppBar>
            <MUIDrawer
                variant="permanent"
                onMouseEnter={handleDrawerOpen}
                onMouseLeave={handleDrawerClose}
                className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,})}
                classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),}}>
                <div className={classes.toolbar}>
                <IconButton onClick={lockDrawer}>
                    <ChevronRightIcon /> 
                </IconButton>
              </div>
              <Divider />
              <List>
              {itemsList.map((item, index) => {
                const { text, icon, onClick } = item;
                return (
                  <ListItem button key={text} onClick={onClick}>
                    {icon && <ListItemIcon>{icon}</ListItemIcon>}
                    <ListItemText primary={text} />
                  </ListItem>
                );
              })}
            </List>
            </MUIDrawer>
      </div>
      )
}

export default withRouter(CustomDrawer);