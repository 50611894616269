import React, {useState, useEffect} from 'react';
import { NativeSelect } from '@material-ui/core';
import styles from './EmailPicker.module.css';
import Button from '@material-ui/core/Button';
import { useAuth0 } from "@auth0/auth0-react";
import { useToasts } from 'react-toast-notifications';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.css"
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
    
import { ServicesWrapper, DateHolder } from './pickerContainer';

const options = [
    {
        label: "All Sites",
        value: "All Sites",
        id: 1,
      },
    {
      label: "Foot Locker",
      value: "Foot Locker",
      id: 2,
    },
    {
      label: "Kids Foot Locker",
      value: "Kids Foot Locker",
      id: 3,
    },
    {
      label: "Champs",
      value: "Champs",
      id: 4,
    },
    {
      label: "Footaction",
      value: "Footaction",
      id: 5,
    },
    {
        label: "Eastbay",
        value: "Eastbay",
        id: 6,
    },
    {
        label: "Nike",
        value: "Nike",
        id: 7,
    },
    {
        label: "Yeezy Supply",
        value: "Yeezy Supply",
        id: 8,
    },
    {
        label: "Supreme",
        value: "Supreme",
        id: 9,
    },
    {
        label: "Kith",
        value: "Kith",
        id: 10,
    },
    {
        label: "Jimmy Jazz",
        value: "Jimmy Jazz",
        id: 11,
    },
    {
        label: "ConceptsIntl",
        value: "ConceptsIntl",
        id: 12,
    },
    {
        label: "Finish Line",
        value: "Finish Line",
        id: 13,
    },
    {
        label: "Hibbett",
        value: "Hibbett",
        id: 14,
    },
    {
        label: "Best Buy",
        value: "Best Buy",
        id: 15,
    },
    {
        label: "Target",
        value: "Target",
        id: 16,
    },
    {
        label: "Walmart",
        value: "Walmart",
        id: 17,
        
    },
    {
        label: "Amazon",
        value: "Amazon",
        id: 18,
        
    },
    {
        label: "Microsoft",
        value: "Microsoft",
        id: 19,
        
    },
    {
        label: "Gamestop",
        value: "Gamestop",
        id: 20,
        
    },
  ];


  const statuses = [
    {
        label: 'All',
        status: 'All',
        id:1,
    },
    {
        label: 'Confirmed',
        status: 'Confirmed',
        id:2,
    },
    {
        label: 'Shipped',
        status: 'Shipped',
        id:3,
    },
    {
        label: 'Cancelled',
        status: 'Cancelled',
        id:4,
    },
    ];
export const EmailPicker = ({onChangeD, onChangeO,newMessage}) => {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState('');
    const [percent, setPercent] = useState('');
    const [orders, setOrders] = useState('');
    const [shipping, setShipping] = useState('');
    const [cancels, setCancels] = useState('');
    const [errors, setErrors] = useState('')
    const [expenses, setExpenses] = useState('');

    const [errorMessage, setErrorMessage] = useState('')

    const { addToast } = useToasts();
    const notifMess = 'Checking emails. If this is your first time checking your emails this could take up to 2-3 minutes per email depending on the number of messages from supported sites. Otherwise this should take <1 minute. Do not click again for ~15 minutes.'
    const { user, getAccessTokenSilently } = useAuth0();
    const [shipStatus, setShipStatus] = useState("All");
    const [site, setSite]  = useState("All Sites")
    const [timeStart, setTimeStart] = useState("Last Week")
    const [timeEnd, setTimeEnd] = useState("Last Week")
    const [timeLength, setTimeLength] =  useState("All Time")
    const domain = "dev-ativ-05e.us.auth0.com";
    const handleChange = async (e) => {
        e.persist()
        setSite(e.target.value)        
        const token = await getAccessTokenSilently({
            audience: `https://${domain}/api/v2/`,
            scope:'read:current_user',
        });
        const userid = user.sub
        const message = {token, userid, site:e.target.value, timeStart, timeEnd, timeLength, shipStatus}

        const response = await fetch("/getQuery", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(message)
        });
        
        if (response.ok) {
            const newInfo = await response.json();
            const newOrders = newInfo.allInfo.allOrders
            const newQueries = newInfo.allInfo.queries
            onChangeO(newOrders)
            onChangeD(newQueries)
        }
    
      }
    const handleDateChange = async (e) => {
        setTimeStart(e[0])
        setTimeEnd(e[1])
        setTimeLength(" ")
        const token = await getAccessTokenSilently({
            audience: `https://${domain}/api/v2/`,
            scope:'read:current_user',
        });
        const userid = user.sub
        const message = {token, userid, site, timeStart:e[0], timeEnd:e[1], timeLength:" ", shipStatus}

        const response = await fetch("/getQuery", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(message)
        });
        
        if (response.ok) {
            const newInfo = await response.json();
            const newOrders = newInfo.allInfo.allOrders
            const newQueries = newInfo.allInfo.queries
            onChangeO(newOrders)
            onChangeD(newQueries)
        }
    
      }
    const handleStatusChange = async (event) => {
        const {
          target: { value },
        } = event;
        setShipStatus(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
        const token = await getAccessTokenSilently({
            audience: `https://${domain}/api/v2/`,
            scope:'read:current_user',
        });
        const userid = user.sub
        const message = {token, userid, site, timeStart, timeEnd, timeLength, shipStatus:value}

        const response = await fetch("/getQuery", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(message)
        });
        
        if (response.ok) {
            const newInfo = await response.json();
            const newOrders = newInfo.allInfo.allOrders
            const newQueries = newInfo.allInfo.queries
            onChangeO(newOrders)
            onChangeD(newQueries)
        }
      };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
      };

    useEffect(() => {
        let intervalId;

        if(open){
            intervalId = setInterval(() => {
                const token = getAccessTokenSilently({
                    audience: `https://${domain}/api/v2/`,
                    scope:'read:current_user',
                });
                const userid = user.sub
                const userURL = "https://dev-ativ-05e.us.auth0.com/api/v2/users/" +userid
                token.then(function(result) {
                    const bearerT = 'Bearer ' + result
                    const response = fetch(userURL, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": bearerT,
                        },
                    })
                    response.then(function(result) {
                        const Info = result.json()
                        Info.then(function(result){
                            try {
                            let info2Add = (result.user_metadata.message.split(/[,]/))
                            let errorMess = result.user_metadata.errorMessage

                            setStatus(info2Add[0])
                            setPercent(info2Add[1])
                            setOrders(info2Add[2])
                            setShipping(info2Add[3])
                            setCancels(info2Add[4])
                            setErrors(info2Add[5])
                            setExpenses(info2Add[6])
                            setErrorMessage(errorMess)
                            } catch(e) { console.error(e); }
                        })
                    })
                 })

                
            }, 10000)
        }
        return () => clearInterval(intervalId);
    },[open,status,user, getAccessTokenSilently])

    const useStyles = makeStyles((theme) => ({
        date: {
          height: 35,
          width: 190,
          fontSize: 14,
          backgroundColor: theme.palette.colors.drawer,
          color: theme.palette.text.primary
        },
        status:{
            width:125,
            marginBottom: 15,
        }
      }));
    const classes2 = useStyles()




    return(
        <ServicesWrapper>
            <NativeSelect justify = "center" className={styles.drop} defaultValue = "" 
            onChange ={handleChange}>
                {options.map((option) => (
                    <option key={option.id} value={option.value}>{option.label}</option>
                ))}
            </NativeSelect>
            <DateHolder>
            <DatePicker
                className={classes2.date}
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                    if(update[1]){
                    handleDateChange(update)
                    }
                    setDateRange(update);
                }}
                isClearable={true}
                placeholderText = "Custom Date Range"
            />
            </DateHolder>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="Status">Status</InputLabel>
        <Select
          labelId="Status"
          id="Status"
          value={shipStatus}
          className = {classes2.status}
          onChange={handleStatusChange}
          label="Age"
        >
          <MenuItem value="All">
            All Statuses
          </MenuItem>
          <MenuItem value="Confirmed">Confirmed</MenuItem>
          <MenuItem value="Shipped">Shipped</MenuItem>
          <MenuItem value="Cancelled">Cancelled</MenuItem>
        </Select>
      </FormControl>
      <div></div>
      <div></div>
            {/* <NativeSelect justify = "center" className={styles.drop} defaultValue = "" 
            label="Status:"
            onChange ={handleStatusChange}>
                {statuses.map((statuses) => (
                    <option key={statuses.id} value={statuses.value}>{statuses.label}</option>
                ))}
            </NativeSelect> */}
            <Button variant="contained" color="secondary" className={styles.butt}
                onClick ={async () => {  
                    const token = await getAccessTokenSilently({
                        audience: `https://${domain}/api/v2/`,
                        scope:'read:current_user',
                    });
                    const userid = user.sub
                    const message = {token, userid, site, timeStart, timeEnd, timeLength:"1D", shipStatus}
                    setTimeLength('1D')

                    const response = await fetch("/getQuery", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(message)
                    });
                    
                    if (response.ok) {
                        const newInfo = await response.json();
                        const newOrders = newInfo.allInfo.allOrders
                        const newQueries = newInfo.allInfo.queries
                        onChangeO(newOrders)
                        onChangeD(newQueries)
                        console.log('getting daily data')
                        


                        
                    }
                }}
                >
                1D
            </Button>

            <Button variant="contained" color="secondary" className={styles.butt}
                onClick ={async () => {  
                    const token = await getAccessTokenSilently({
                        audience: `https://${domain}/api/v2/`,
                        scope:'read:current_user',
                    });
                    const userid = user.sub
                    const message = {token, userid, site, timeStart, timeEnd, timeLength:"1W", shipStatus}
                    setTimeLength('1W')

                    const response = await fetch("/getQuery", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(message)
                    });
                    
                    if (response.ok) {
                        const newInfo = await response.json();
                        const newOrders = newInfo.allInfo.allOrders
                        const newQueries = newInfo.allInfo.queries
                        onChangeO(newOrders)
                        onChangeD(newQueries)
                    }
                }}>
                1W
            </Button>
            <Button variant="contained" color="secondary" className={styles.butt}
            onClick ={async () => {  
                const token = await getAccessTokenSilently({
                    audience: `https://${domain}/api/v2/`,
                    scope:'read:current_user',
                });
                const userid = user.sub
                const message = {token, userid, site, timeStart, timeEnd, timeLength:"1M", shipStatus}
                setTimeLength('1M')

                const response = await fetch("/getQuery", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(message)
                });
                
                if (response.ok) {
                    const newInfo = await response.json();
                    const newOrders = newInfo.allInfo.allOrders
                    const newQueries = newInfo.allInfo.queries
                    onChangeO(newOrders)
                    onChangeD(newQueries)
                }
                }}>
                1M
            </Button>
            <Button variant="contained" color="secondary" className={styles.butt}
                onClick ={async () => {  
                    const token = await getAccessTokenSilently({
                        audience: `https://${domain}/api/v2/`,
                        scope:'read:current_user',
                    });
                    const userid = user.sub
                    const message = {token, userid, site, timeStart, timeEnd, timeLength:"3M", shipStatus}
                    setTimeLength('3M')
                    const response = await fetch("/getQuery", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(message)
                    });
                    
                    if (response.ok) {
                        const newInfo = await response.json();
                        const newOrders = newInfo.allInfo.allOrders
                        const newQueries = newInfo.allInfo.queries
                        onChangeO(newOrders)
                        onChangeD(newQueries)
                    }
                }}>
                3M
            </Button>
            <Button variant="contained" color="secondary" className={styles.butt2}
                onClick ={async () => {  
                    const token = await getAccessTokenSilently({
                        audience: `https://${domain}/api/v2/`,
                        scope:'read:current_user',
                    });
                    const userid = user.sub
                    const message = {token, userid, site, timeStart, timeEnd, timeLength:"All Time", shipStatus}
                    setTimeLength('All Time')

                    const response = await fetch("/getQuery", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(message)
                    });
                    
                    if (response.ok) {
                        const newInfo = await response.json();
                        const newOrders = newInfo.allInfo.allOrders
                        const newQueries = newInfo.allInfo.queries
                        onChangeO(newOrders)
                        onChangeD(newQueries)
                    }
                }}>
                All Time
            </Button>
            <Button variant="contained" color="secondary" className={styles.butt3}
                onClick ={async () => {  
                    const token = await getAccessTokenSilently({
                        audience: `https://${domain}/api/v2/`,
                        scope:'read:current_user',
                    });
                    const userid = user.sub
                    const disName = user.name
                    const message = {token, userid, disName}
                    const responseA = await fetch("/checkEmail2", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(message)
                    });

                    if (responseA.ok) {

                        const data = await responseA.json();
                        if (data.output !== 'done'){
                            addToast(data.output,  { appearance: 'error' , autoDismiss: true})
                        } else {
                            addToast(notifMess, { appearance: 'info', autoDismiss: true })
                        }
                        setStatus('Estimating time remaining...')
                        setPercent('')
                        setOrders('')
                        setShipping('')
                        setErrors('')
                        setCancels('')
                        setExpenses('')
                        setErrorMessage('')
                        console.log("Sent token to backend");
                    }
                    
                }}>
                Check Email
            </Button>
                <LaunchTwoToneIcon onClick={handleClickOpen}>

                </LaunchTwoToneIcon>
                <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth={true} aria-labelledby="form3">
                    <DialogTitle id="form3">Check Email Status</DialogTitle>
                        <DialogContent dividers>
                            <DialogContentText>
                                {status}
                            </DialogContentText>
                            <DialogContentText>
                                {percent}
                            </DialogContentText>
                            <DialogContentText>
                                {orders}
                            </DialogContentText>
                            <DialogContentText>
                                {shipping}
                            </DialogContentText>
                            <DialogContentText>
                                {expenses}
                            </DialogContentText>
                            <DialogContentText>
                                {cancels}
                            </DialogContentText>
                            <DialogContentText>
                                {errors}
                            </DialogContentText>
                            <DialogContentText style={{color: "#DC676C"}}>
                                {errorMessage}
                            </DialogContentText>
                        </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </ServicesWrapper>
        
    )
}

export default EmailPicker;