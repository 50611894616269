// src/components/logout-button.js

import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Box from "@mui/material/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { BoxContent } from "./Cards/CardsContent";

const LogoutButton = () => {
  const domain = "dev-ativ-05e.us.auth0.com";
  const { user, getAccessTokenSilently } = useAuth0();
  const { logout } = useAuth0();
  const [name] = useState(user.name);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [betaUser, setBetaUser] = useState(false);
  const [key, setKey] = useState("");

  // useEffect(() => {
  //   const getUserMetadata = async () => {
  //     const token = await getAccessTokenSilently({
  //       audience: `https://${domain}/api/v2/`,
  //       scope: "read:current_user",
  //     });
  //     const userid = user.sub;
  //     const bearerT = "Bearer " + token;
  //     const userURL =
  //       "https://dev-ativ-05e.us.auth0.com/api/v2/users/" + userid;

  //     const response = await fetch(userURL, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: bearerT,
  //       },
  //     });

  //     if (response.ok) {
  //       const userInfo = await response.json();
  //       if (typeof userInfo.user_metadata !== "undefined") {
  //         if (!userInfo.user_metadata.key) {
  //           const webhookExist = '{"user_metadata": {"key": ""}}';
  //           const response2 = await fetch(userURL, {
  //             method: "PATCH",
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: bearerT,
  //             },
  //             body: webhookExist,
  //           });
  //           if (response2.ok) {
  //             console.log("set key");
  //           }
  //           setKey("");
  //         } else {
  //           const keyExist = userInfo.user_metadata.key;
  //           setKey(keyExist);
  //         }
  //       }
  //     }
  //   };
  //   getUserMetadata();
  // }, [getAccessTokenSilently, user]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseD = () => {
    setOpenD(false);
  };
  const [openD, setOpenD] = useState(false);
  const handleClickOpen = () => {
    setOpenD(true);
  };
  const handleUpdate = async (event) => {
    const token = await getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scope: "read:current_user",
    });
    const userid = user.sub;
    const bearerT = "Bearer " + token;
    const userURL = `https://dev-ativ-05e.us.auth0.com/api/v2/users/${userid}`;
    const newKey = '{"user_metadata": {"key": "' + key.toString() + '"}}';
    const response2 = await fetch(userURL, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerT,
      },
      body: newKey,
    });
    if (response2.ok) {
      console.log("updated settings");
      handleCloseD();
    }
  };

  useEffect(() => {
    const getUserMetadata = async () => {
      const message = user.sub;
      const response = await fetch("/checkForBeta", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(message),
      });

      if (response.ok) {
        const isBeta = await response.json();
        if (isBeta.isBeta) {
          setBetaUser("Active");
        } else {
          setBetaUser("INACTIVE");
        }
      }
    };
    getUserMetadata();
  }, [user]);

  const useStyles = makeStyles((theme) => ({
    custom: {
      color: theme.palette.text.primary,
      marginTop: 3,
    },
    arrow: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    arrow1: {
      color: theme.palette.text.primary,
      "&:hover": {
        color: "#F1E0C5",
      },
      marginTop: 1,
    },
    menu: {
      size: 50,
      color: theme.palette.text.primary,
      "& .MuiPaper-root": {
        backgroundColor: theme.palette.colors.logout,
        color: theme.palette.text.primary,
      },
      "& div": {
        // this is just an example, you can use vw, etc.
        width: "175px",
      },
    },
  }));
  const classes = useStyles();
  const stringSpace = "         ";

  const tempName =
    name.length > 10
      ? name.substring(0, 10) + "..."
      : name + stringSpace.substring(0, 10 - name.length);

  return (
    <div>
      {/* <Box
        onClick={handleClick}
        // className={classes.box}
        sx={{
          p: 2,
          bgcolor: (theme) => theme.palette.secondary.main,
          border: 1,
          borderColor: "#192841",
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gap: 1,
          borderRadius: 3,
          padding: 1,
          width: "175px",
          maxHeight: 45,
          "&:hover": {
            borderColor: "#F1E0C5",
          },
        }}
      >
        <i
          style={{
            borderRadius: "50%",
            width: 30,
            height: 30,
            background: `url(${user.picture})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        />
        <Typography variant="body2" className={classes.custom}>
          {tempName}
        </Typography>

        <KeyboardArrowDownRoundedIcon
          className={classes.arrow1}
          style={{ size: "white" }}
        />
      </Box> */}
      <BoxContent onClick={handleClick}>
        <i
          style={{
            borderRadius: "50%",
            width: 30,
            height: 30,
            background: `url(${user.picture})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        />
        <Typography variant="body2" className={classes.custom}>
          {tempName}
        </Typography>

        <KeyboardArrowDownRoundedIcon
          className={classes.arrow1}
          style={{ size: "white" }}
        />
      </BoxContent>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        className={classes.menu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <Typography variant="body2">{"Subscription: " + betaUser}</Typography>
        </MenuItem>
        {/* <MenuItem onClick={handleClickOpen}>
          <Typography variant="body2">{"Enter License Key"}</Typography>
        </MenuItem> */}
        <MenuItem
          onClick={() =>
            logout({
              returnTo: window.location.origin,
            })
          }
        >
          <Typography variant="body2">Logout </Typography>
        </MenuItem>
      </Menu>
      <Dialog
        open={openD}
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        aria-labelledby="form4"
      >
        <DialogTitle id="form4">License Key</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter your license key below</DialogContentText>
          <Grid container spacing={1} justify="space-between">
            <TextField
              autoFocus
              fullWidth
              margin="dense"
              id="key"
              label="License Key"
              defaultValue={key}
              onChange={(e) => {
                setKey(e.target.value);
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseD} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdate} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LogoutButton;
