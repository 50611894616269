import React, { useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";

// const theme = createTheme({
//   palette: {
//     type:
//       localStorage.getItem("theme") === "theme-dark"
//         ? console.log("toggle")
//         : console.log("toggle"),
//     background: {
//       // default: "#080419",
//       default: "#101725",
//       paper: "#192841",
//     },
//     primary: {
//       // main: "#cc9b5c",
//       main: "#F1E0C5",
//     },
//     secondary: {
//       main: "#F1E0C5",
//     },
//   },
// typography: {
//   fontFamily: ["Encode Sans Expanded", "sans-serif"].join(","),
// },
//fontFamily: font // as an aside, highly recommend importing roboto font for Material UI projects! Looks really nice
// });

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Auth0ProviderWithHistory scope="read:roles update:users read:users read:current_user update:current_user_metadata read:emails">
        {/* <ThemeProvider theme={theme}> */}
        <App />
        {/* </ThemeProvider> */}
      </Auth0ProviderWithHistory>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
