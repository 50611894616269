import styled, { useTheme } from "styled-components";
import { withTheme } from "@material-ui/core/styles";

// const theme = useTheme();

export const ServicesWrapper = styled.div`
  max-width: 2500px;
  margin: 40px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 50px;
  //padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;
export const BoxContent = withTheme(styled.div`
  border-radius: 13px;
  padding: 10px;
  width: 175px;
  height: 45px;
  background-color: ${(props) => props.theme.palette.colors.logout};
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  padding: 0 10px;
  &:hover {
    border: 1px solid #f1e0c5;
  }
`);

export const ServicesCard = withTheme(styled.div`
  /* background-color: rgba(25, 40, 65, 1); */
  background-color: ${(props) => props.theme.palette.background.paper};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  border-radius: 5px;
  max-height: 400px;
  max-width: 500px;
  min-height: 160px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
`);

export const Line = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
  width: 100%;
  border-top: 1px solid grey;
  margin-top: 5px;
`;
