import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import { useAuth0 } from "@auth0/auth0-react";
import { DangerWrapper } from './DangerSettingsContainer';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import styles from './DangerSettings.module.css'


export const DangerSettings = () => {
    const domain = "dev-ativ-05e.us.auth0.com";   
    const { user, getAccessTokenSilently } = useAuth0();
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const handleClose2 = () => {
        setOpen2(false);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    return(
        <DangerWrapper>
            <Button style={{backgroundColor: "#DC676C"}} variant="contained" color="primary" className={styles.butt}
                onClick={handleClickOpen}>
                Delete Order Hub Items
            </Button>
            <Button style={{backgroundColor: "#DC676C"}} variant="contained" color="primary" className={styles.butt}
                onClick={handleClickOpen2}>
                Delete Scraped Expenses
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form4">
                <DialogTitle id="form4">Delete orders from Order Hub</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Are you sure you want to permanently all orders from the Order Hub?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button style={{backgroundColor: "#DC676C"}} 
                    onClick ={async () => {  
                        const token = await getAccessTokenSilently({
                            audience: `https://${domain}/api/v2/`,
                            scope:'read:current_user',
                        });
                        const userid = user.sub
                        const message = {token, userid}
                        const response = await fetch("/deleteOrders", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify(message)
                        });
                        if (response.ok) {
                            console.log("response worked!");
                        }
                        handleClose()

                    }}>
                    Yes, Delete
                </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={open2} onClose={handleClose2} aria-labelledby="form5">
                <DialogTitle id="form4">Delete scraped expenses</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Are you sure you want to permanently all expenses found from the Order Hub?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose2} color="primary">
                    Cancel
                </Button>
                <Button style={{backgroundColor: "#DC676C"}} 
                    onClick ={async () => {  
                        const token = await getAccessTokenSilently({
                            audience: `https://${domain}/api/v2/`,
                            scope:'read:current_user',
                        });
                        const userid = user.sub
                        const message = {token, userid}
                        const response = await fetch("/deleteExpenses", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify(message)
                        });
                        if (response.ok) {
                            console.log("response worked!");
                        }
                        handleClose2()

                    }}>
                    Yes, Delete
                </Button>
                </DialogActions>
            </Dialog>
    </DangerWrapper>

    )
}

export default DangerSettings;