import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Cards,EmailPicker, Orders,} from '../Component';
import { Grid , Typography } from '@material-ui/core';
import styles from '../App.module.css';
import { useAuth0 } from "@auth0/auth0-react";
import CustomDrawer from "../Component/CustomDrawer"
import { TableWrapper } from "../Component/InventoryCards/InvCardContent";

const OrderHub = props => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [betaUser, setBetaUser] = useState(false)
  const [keyValid, setKeyValid] = useState(false)
  const [key, setKey] = useState('')
  const BetaRole = user['http://user.com/roles'][0] === 'Beta User'

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = "dev-ativ-05e.us.auth0.com";
      
      const token = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope:'read:current_user',
      });
      const userid = user.sub
      const message = {token, userid, site:'All Sites', timeStart:'Last Week', timeEnd:'Last Week', timeLength:'All Time',shipStatus:'All'}

      const response = await fetch("/getQuery", {
          method: "POST",
          headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify(message)
      });

        if (response.ok) {
          const newInfo = await response.json();
          const newOrders = newInfo.allInfo.allOrders
          const newQueries = newInfo.allInfo.queries

          setOrders(newOrders)
          setQuery(newQueries)

          console.log("response worked!")
        }
      }; 
    getUserMetadata();
  }, [user, getAccessTokenSilently]);

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = "dev-ativ-05e.us.auth0.com";
      const token = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope:'read:current_user',
      });
      const userid = user.sub
      const bearerT = 'Bearer ' + token
      const userURL = "https://dev-ativ-05e.us.auth0.com/api/v2/users/" +userid

      const response = await fetch(userURL, {
          method: "GET",
          headers: {
              "Content-Type": "application/json",
              "Authorization": bearerT,
          },
      });

      if (response.ok) {
        const userdata = await response.json();
        if (typeof userdata.user_metadata.key !== "undefined") {
          setKey(userdata.user_metadata.key)
        }
        try{
        const newMessage = userdata.user_metadata.message
        setMessage(newMessage)
        }catch(e) { }
      }
      }; 
    getUserMetadata();
  }, [user,getAccessTokenSilently]);
  
  useEffect(() => {
    const getUserMetadata = async () => {
      if (!BetaRole){
        const message = user.sub
        const response = await fetch("/checkForBeta", {
          method: "POST",
          headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify(message)
        });

        if (response.ok) {
          const isBeta = await response.json();
          setBetaUser(isBeta.isBeta)
        }
      }
      }; 
    getUserMetadata();
  }, [user,getAccessTokenSilently,BetaRole]);

  // useEffect(() => {
  //   const getKeyData = async () => {
  //     const bTok = process.env.REACT_APP_WHOP_BEARER;
  //     const bearerT = `Bearer ${bTok}`
  //     const URL = `https://api.whop.io/api/v1/licenses/${key}`
  //     const response2 = await fetch(URL, {
  //         method: "GET",
  //         headers: {
  //             "Content-Type": "application/json",
  //             "Authorization": bearerT,
  //         },
  //     })
  //     if (response2.ok) {
  //       const keyInfo = await response2.json();
  //       if (typeof keyInfo.plan !== "undefined") {
  //         if (keyInfo.plan.id === 1422 | keyInfo.plan.id === 1424 | keyInfo.plan.id === 1794 | keyInfo.plan.id === 2004){
  //           if (user.sub.includes(keyInfo.discord.discord_account_id)){
  //             console.log('valid plan and matching discord')
  //             setKeyValid(true)
  //           }
  //         }
  //       }
  //     } else {
  //       console.log('invalid key')
  //     }
  //     }; 
  //   getKeyData();
  // }, );

  const [queries, setQuery] = useState([]);
  const [orders, setOrders] = useState([]);
  const [message, setMessage] = useState([]);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }));
  const classes = useStyles();

  const { history } = props;
 
  

  const display = (betaUser | BetaRole ) ?             
  <Container maxWidth="lg" className={classes.container}>
  <Grid container spacing={2} justifyContent ="flex-start" className={styles.title}>
    <Typography gutterBottom variant="h5">Order Hub</Typography>
  </Grid>
  <EmailPicker onChangeD={queries  => {setQuery(currentQuery => queries)}} onChangeO={orders  => {setOrders(currentOrders => orders)}} newMessage={message}/>
  <Cards queries={queries}/>
  <TableWrapper>
    <Orders onChangeD={queries  => {setQuery(currentQuery => queries)}} onChangeO={orders  => {setOrders(currentOrders => orders)}} orders = {orders}/>
  </TableWrapper>
</Container> :
<Typography></Typography>
  return (
    <div className={classes.root}>
      {/* <CustomDrawer props={props}/> */}

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {display}
      </main>

    
    </div>

      


  )


}; 

export default OrderHub;
