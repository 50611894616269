import styled, { useTheme } from "styled-components";
import { withTheme } from "@material-ui/core/styles";

// const theme = useTheme();

export const CardWrapper = styled.div`
  /* max-width: 1000px; */
  /* margin: 40px auto; */
  max-height: 250px;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 30px;
`;

export const ChartWrapper = withTheme(styled.div`
  padding: 3px;
  max-height: 300px;
  height: 100%;
  background-color: ${(props) => props.theme.palette.background.paper};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`);

export const TableWrapper = withTheme(styled.div`
  padding: 10px;
  margin-top: 30px;
  /* max-height: 250px; */
  height: 100%;
  background-color: ${(props) => props.theme.palette.background.paper};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`);

export const CardChartWrapper = styled.div`
  max-width: 2500px;
  margin-top: 20px;
  /* margin: 40px auto; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  /* justify-content: flex-start; */
  grid-gap: 25px;
`;

export const InventoryCard = withTheme(styled.div`
  /* background-color: rgba(25, 40, 65, 1); */
  background-color: ${(props) => props.theme.palette.background.paper};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* align-items: center; */
  border-radius: 8px;
  max-height: 150px;
  min-height: 90px;
  min-width: 250px;
  max-width: 350px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
`);

export const CardInfo = styled.div`
  flex-grow: 1;
  text-align: center;
  z-index: 1;
  text-transform: capitalize;
`;

export const VLine = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
  max-width: 3px;
  /* width: 1px; */
  margin-left: 20px;
  border-left: 1px solid grey;
  margin-top: 2px;
`;

export const StyledIcon = styled.div`
  width: 20%;
  /* height: 100%; */
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;
