import React from "react";
import Icon1 from "../../images/svg-5.svg";
import Icon2 from "../../images/svg-7.svg";
import Icon3 from "../../images/svg-6.svg";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./ServicesElements";

const Services = () => {
  return (
    <ServicesContainer id="services">
      <ServicesH1>Our Services</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Search Your Emails</ServicesH2>
          <ServicesP>
            Have several email addresses where you recieve orders? auttr
            automatically searchs your emails for your orders.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Manage Your Inventory</ServicesH2>
          <ServicesP>
            Easily view the items in your inventory and track your sales and
            profits with ease. No more spreadsheets.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>View your profits</ServicesH2>
          <ServicesP>
            Easily view your profits and expenses. Analytics from auttr help you
            understand what makes your business most successful.
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
