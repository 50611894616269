import React, {useState, forwardRef} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DatePicker from "react-datepicker";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import StickyTable from "react-sticky-table-thead"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { useAuth0 } from "@auth0/auth0-react";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import SellIcon from '@mui/icons-material/Sell';
import { DateHolder } from '../EmailPicker/pickerContainer';
import styles from './Inventory.module.css';


function descendingComparator(a, b, orderBy) {
  if (orderBy === 'date'){
    const dateA = new Date(a.date)
    const dateB = new Date(b.date)
    if (dateB < dateA) {
      return -1;
    }
    if (dateB > dateA) {
      return 1;
    }
    return 0;
  } else{
    
    if (orderBy === 'name' || orderBy === 'size' || orderBy === 'website') {
      if (b[orderBy].toUpperCase() < a[orderBy].toUpperCase()) {
        return -1;
      }
      if (b[orderBy].toUpperCase() > a[orderBy].toUpperCase()) {
        return 1;
      }
      return 0;

    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
  }
}

}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'size', numeric: false, disablePadding: false, label: 'Size' },
    { id: 'cost', numeric: false, disablePadding: false, label: 'Cost($)' },
    { id: 'website', numeric: true, disablePadding: false, label: 'Site' },
  ];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor:theme.palette.background.paper,
      color: theme.palette.text.primary,
      position: 'sticky',
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            color="primary"
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  selectedId: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:{
    color: "#1c1d1f",
    backgroundColor: "#F1E0C5",
  },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, setOpen, setOpen2, setOpen3, setOpen4, setName, setDate, setSize, setCost, setSite, selected, inventory } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };


  const handleClickOpen3 = () => {
    setOpen3(true);
  };

  const handleEdit = () => {
    setOpen4(true);
    setName(inventory[selected[0]-1].name)
    setDate(inventory[selected[0]-1].date)
    setSize(inventory[selected[0]-1].size)
    setCost(inventory[selected[0]-1].cost)
    setSite(inventory[selected[0]-1].website)

  }
  

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Current Inventory
        </Typography>
      )}

      {(numSelected > 0 & numSelected <2) ? (
        <Tooltip title="Edit Item">
          <IconButton aria-label="Edit Item">
            <EditIcon onClick={handleEdit} style={{fill: "#1c1d1f"}}/>
          </IconButton>
        </Tooltip>
      ) : ( null)}

      {numSelected > 0 ? (
        <Tooltip title="Mark as Sold">
          <IconButton aria-label="Mark as Sold">
            <SellIcon onClick={handleClickOpen} style={{fill: "#1c1d1f"}}/>
          </IconButton>
        </Tooltip>

      ) : (
        <Tooltip title="Add">
          <IconButton aria-label="Add">
            <AddIcon onClick={handleClickOpen2} />
          </IconButton>
        </Tooltip>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="Delete">
            <DeleteForeverOutlinedIcon onClick={handleClickOpen3} style={{fill: "#DC676C"}}/>
          </IconButton>
        </Tooltip>
      ) : ( null)}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  setSelected: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    width: '100%',
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  formControl1: {
    margin: theme.spacing(1),
    minWidth: 160,
  },  
  row: {
    fontSize: 14,
  },
  row2:{
    fontSize: 14,
    color: theme.palette.colors.hyperlink,
    textDecorationLine: 'underline',
  },
  dialogPaper: {
    minHeight: '75vh',
    maxHeight: '75vh',
},
  checkbox:{
    color:"red",
  }
}));

export default function InventoryOverview({inventory, onChangeI}) {
  const rows = inventory
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);

  const [salePrice, setSalePrice] = React.useState("");
  const [sellingPlatform, setSellingPlatform] = React.useState("");
  const [percent, setPercent] = React.useState("");
  const [date, setDate] = React.useState(new Date());
  const [name, setName] = React.useState("");
  const [size, setSize] = React.useState("");
  const [cost, setCost] = React.useState("");
  const [site, setSite] = React.useState("");
  const [charged, setCharge] = React.useState("");
  const [location, setLocation] = React.useState("");
  const domain = "dev-ativ-05e.us.auth0.com";
  const [startDate, setStartDate] = React.useState(new Date());
  const { user, getAccessTokenSilently } = useAuth0();
  const [calOpen, setCalOpen] = React.useState(true);

  const handleDateChange = (e) => {
    console.log(e);
};
  const handleClose = () => {
    setOpen(false);
    setSalePrice("");
    setSellingPlatform("");
    setPercent("");
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };
  const handleClose4 = () => {
    setOpen4(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const numEntries = rows.length

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        // backgroundColor: theme.palette.action.hover,
        backgroundColor:theme.palette.background.paper,
  
      },
    },
  }))(TableRow);

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const handleTrack = (tNumber) => {
    if (tNumber !== '' && tNumber !=='None') {
    return (event) => {
      window.open(tNumber, "_blank")
    }}
  }
  // const CustomDate = () => {
  //   const [startDate, setStartDate] = useState(new Date());
  //   const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
  //     <TextField 
  //     margin="dense"
  //     id="Date"
  //     label="Date (mm/dd/yy)"
  //     // className="example-custom-input" 
  //     onClick={onClick} ref={ref}>
  //       {value}
  //     </TextField>
  //   ));
  //   return (
  //     <DatePicker
  //       selected={startDate}
  //       onChange={(date) => setStartDate(date)}
  //       customInput={<ExampleCustomInput />}
  //     />
  //   );
  // };
  const handleCalendarClose = () => setCalOpen(true);
  const handleCalendarOpen = () => setCalOpen(true);
  const CustomDate = () => {

  
    // const [startDate, setStartDate] = useState(new Date());
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
      <TextField 
      margin="dense"
      id="Date"
      label="Date (mm/dd/yy)"
      // className="example-custom-input" 
      onClick={onClick} 
      ref={ref}
      defaultValue = {value}
      >
        {value}
      </TextField>
    //   <button className="example-custom-input" onClick={onClick} ref={ref}>
    //   {value}
    // </button>
    ));
    return (
      // <DatePicker
      //   selected={startDate}
      //   onChange={(update) => {
      //     handleDateChange(update)
      //     setDate(update);
      //     setStartDate(update);
      //   }}
      //   // startDate={startDate}

      //   popperPlacement="bottom-start"
      //   onCalendarClose={handleCalendarClose}
      //   onCalendarOpen={handleCalendarOpen}
      //   customInput={<ExampleCustomInput />}
      // />
      <DatePicker
      selected={startDate}
      onChange={(date) => {setStartDate(date)}}
      customInput={<ExampleCustomInput />}
      popperPlacement="bottom-start"
        onCalendarOpen={handleCalendarOpen}
        onCalendarClose={handleCalendarClose}

    />
    );
  };

  return (
    <div className={classes.root}>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Enter Sales Data</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter all information for a sale. This information will be saved for all selected items. 
          </DialogContentText>
          <Grid container spacing={1} justify ="space-between">
          <TextField
              autoFocus
              margin="dense"
              id="SalePrice"
              label="Sale Price"
              onChange ={e => {setSalePrice(e.target.value)}}
          />
          <FormControl className={classes.formControl}>
            <InputLabel id="Selling Platform">Selling Platform</InputLabel>
              <Select
                labelId="Selling Platform"
                id="Selling Platform"
                value={sellingPlatform}
                onChange={e => {setSellingPlatform(e.target.value)}}
              >
              <MenuItem value={"GOAT"}>GOAT</MenuItem>
              <MenuItem value={"StockX"}>StockX</MenuItem>
              <MenuItem value={"eBay"}>eBay</MenuItem>
              <MenuItem value={"Graild"}>Grailed</MenuItem>
              <MenuItem value={"Local"}>Local</MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
              </Select>
          </FormControl>
          <TextField
              margin="dense"
              id="PercentToGive"
              label="Fee (%)"
              onChange ={e => {setPercent(e.target.value)}}
          />
           </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button color="primary" onClick ={async () => {  
                  const token = await getAccessTokenSilently({
                      audience: `https://${domain}/api/v2/`,
                      scope:'read:current_user',
                  });
                  const userid = user.sub
                  const tempMes = selected
                  var i;
                  var textM = ""
                  for (i = 0; i < tempMes.length; i++) {
                  textM += tempMes[i].toString() + ",";
                  }
                  const message = {token, userid, ids: textM, salePrice:salePrice, sellingPlatform:sellingPlatform,percent:percent}
                  const responseA = await fetch("/addSale", {
                      method: "POST",
                      headers: {
                          "Content-Type": "application/json"
                      },
                      body: JSON.stringify(message)
                  });
                  setSellingPlatform("")
                  setSalePrice("")
                  setPercent("")
                  if (responseA.ok) {
                      console.log("response worked!");
                      setSelected([])

                      const token = await getAccessTokenSilently({
                        audience: `https://${domain}/api/v2/`,
                        scope: "read:current_user",
                      });
                      const userid = user.sub
                      const tokmessage = {token, userid}
                      const response3 = await fetch("/currInventory", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(tokmessage)
                        
                      });                
                      const newI = await response3.json();
                      onChangeI(newI.inventory)
                      setSelected([])
                    }
                    handleClose()
                  }} >
                      
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog classes={{ paper: classes.dialogPaper }} 
            classes = {(calOpen) ? ({ paper: classes.dialogPaper }):("")}

      open={open2} onClose={handleClose2} aria-labelledby="form2" dividers={false}>
        <DialogTitle id="form2">Manual Entry</DialogTitle>
        <DialogContent>
          <DialogContentText>
              Enter items manually for sites not supported by Email Scraper
          </DialogContentText>
          <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Name"
                fullWidth
                onChange ={e => {setName(e.target.value)}}/>
          <Grid container justify ="space-between">
              <TextField
                  margin="dense"
                  id="Retail"
                  label="Retail"
                  onChange ={e => {setCost(e.target.value)}}/>
              <TextField
                  margin="dense"
                  id="Site"
                  label="Site"
                  onChange ={e => {setSite(e.target.value)}}/>

          </Grid>
          <Grid container justify ="space-between">
          <CustomDate></CustomDate>

          <TextField
                className={styles.drop}
                margin="dense"
                id="Size"
                label="Size"
                onChange ={e => {setSize(e.target.value)}}/>
            
          </Grid>
        {/* <Grid container justify ="space-between">
          <div></div>
        </Grid> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Cancel
          </Button>
          <Button color="primary" onClick ={async () => { 
            const token = await getAccessTokenSilently({
                audience: `https://${domain}/api/v2/`,
                scope:'read:current_user',
            });
            const userid = user.sub
            if (name !== ""){
              const editDate = new Date(startDate)
              const dayOfI = editDate.getDate()-1
              editDate.setDate(dayOfI);
              const message1 = {token, userid,name:name, date:editDate,size:size,retail:cost,charged:charged,site:site,location:location}
              const responseA = await fetch("/addNewItem", {
                  method: "POST",
                  headers: {
                      "Content-Type": "application/json"
                  },
                  body: JSON.stringify(message1)
              });
              if (responseA.ok) {
                  console.log("response worked!");
                  setName("")
                  setSize("")
                  setCost("")
                  setCharge("")
                  setSite("")
                  setLocation("")
                  const token = await getAccessTokenSilently({
                    audience: `https://${domain}/api/v2/`,
                    scope: "read:current_user",
                  });
                  const userid = user.sub
                  const tokmessage = {token, userid}
                  const response3 = await fetch("/currInventory", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(tokmessage)
                    
                  });                
                  const newI = await response3.json();
                  onChangeI(newI.inventory)
                  handleClose2()
                }
            } else{
              handleClose2()
            }
              
            }} >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open3} onClose={handleClose3} aria-labelledby="form3">
        <DialogTitle id="form3">Delete Entries</DialogTitle>
        <DialogContent>
          <DialogContentText>
              Are you sure you want to permanently delete the selected items?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose3} color="primary">
            Cancel
          </Button>
          <Button style={{backgroundColor: "#DC676C"}} onClick ={async () => { 
            const token = await getAccessTokenSilently({
                audience: `https://${domain}/api/v2/`,
                scope:'read:current_user',
            });
            const userid = user.sub
            const tempMes = selected
            var i;
            var textM = ""
            for (i = 0; i < tempMes.length; i++) {
            textM += tempMes[i].toString() + ",";
            }

            const message = {token, userid, ids: textM}
            const responseA = await fetch("/deleteItem", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(message)
            });
            
            if (responseA.ok) {
              console.log("response worked!");
              setSelected([])

              const token = await getAccessTokenSilently({
                audience: `https://${domain}/api/v2/`,
                scope: "read:current_user",
              });
              const userid = user.sub
              const tokmessage = {token, userid}
              const response3 = await fetch("/currInventory", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(tokmessage)
                
              });                
              const newI = await response3.json();
              onChangeI(newI.inventory)
              handleClose3()
            }              
            }} >
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth={true}
        maxWidth="sm" open={open4} onClose={handleClose4} aria-labelledby="form4">
        <DialogTitle id="form2">Edit Item</DialogTitle>
        <DialogContent>
          <TextField
                margin="dense"
                id="name"
                label="Name"
                fullWidth
                defaultValue = {(selected === "" | selected.length === 0) ? (""):(inventory[selected[0]-1].name)}
                onChange ={e => {setName(e.target.value)}}

                />
          <Grid container justify ="space-between">
            <TextField
                  disabled
                  margin="dense"
                  id="Date"
                  label="Date (mm/dd/yy)"
                  defaultValue = {(selected === "" | selected.length === 0) ? (""):(inventory[selected[0]-1].date)}
                  onChange ={e => {setDate(e.target.value)}}/>
            <TextField
                margin="dense"
                id="Size"
                label="Size"
                defaultValue = {(selected === "" | selected.length === 0) ? (""):(inventory[selected[0]-1].size)}
                onChange ={e => {setSize(e.target.value)}}/>
          </Grid>
          <Grid container justify ="space-between">
              <TextField
                  margin="dense"
                  id="Cost"
                  label="Cost"
                  defaultValue = {(selected === "" | selected.length === 0)? (""):(inventory[selected[0]-1].cost)}
                  onChange ={e => {setCost(e.target.value)}}/>
              <TextField
                  margin="dense"
                  id="Site"
                  label="Site"
                  defaultValue = {(selected === "" | selected.length === 0) ? (""):(inventory[selected[0]-1].website)}
                  onChange ={e => {setSite(e.target.value)}}/>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose4} color="primary">
            Cancel
          </Button>
          <Button color="primary" onClick ={async () => { 
            const token = await getAccessTokenSilently({
                audience: `https://${domain}/api/v2/`,
                scope:'read:current_user',
            });
            const userid = user.sub
            if (name !== ""){
              const tempMes = selected
              var i;
              var textM = ""
              for (i = 0; i < tempMes.length; i++) {
              textM += tempMes[i].toString() + ",";
              }
              const message1 = {token, userid,ids: textM, name:name, date:date,size:size,retail:cost,site:site}
              const responseA = await fetch("/editItem", {
                  method: "POST",
                  headers: {
                      "Content-Type": "application/json"
                  },
                  body: JSON.stringify(message1)
              });
              if (responseA.ok) {
                  console.log("response worked!");
                  setName("")
                  setSize("")
                  setCost("")
                  setSite("")

                  const tokmessage = {token, userid}
                  const response3 = await fetch("/currInventory", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(tokmessage)
                    
                  });                
                  const newI = await response3.json();
                  onChangeI(newI.inventory)
                  handleClose4()
                }
            } else{
              handleClose4()
            }
              
            }} >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
        <EnhancedTableToolbar numSelected={selected.length} selectedId={selected} onChangeI = {onChangeI} setSelected={setSelected} open = {open} setOpen = {setOpen} open2 = {open2} setOpen2 = {setOpen2}  
        open3 = {open3} setOpen3 = {setOpen3} open4 = {open4} setOpen4 = {setOpen4} setName = {setName} setDate = {setDate} setSize = {setSize} setCost={setCost} setSite={setSite} selected={selected} inventory={inventory}/>
        <TableContainer>
        {/* <StickyTable height={400}> */}
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              selectedId={selected.id}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <StyledTableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          color="primary"
                          // className={classes.checkbox}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left" component="th" id={labelId} scope="row" padding="none">
                        {row.date}
                      </StyledTableCell>
                      <TableCell className={(row.orderInfo === 'None') ? classes.row : classes.row2} align="left" onClick={handleTrack(row.orderInfo)}>{row.name}</TableCell>
                      <StyledTableCell align="left">{row.size}</StyledTableCell>
                      <StyledTableCell align="left">{row.cost}</StyledTableCell>
                      <StyledTableCell align="right">{row.website}</StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {/* </StickyTable> */}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

    </div>
  );
}
