import React, {useEffect, useState} from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import styles from '../App.module.css';
import InventoryOverview from '../Component/Inventory/InventoryOverview';
import { useAuth0 } from "@auth0/auth0-react";
import CustomDrawer from "../Component/CustomDrawer"
import {InventoryCards} from "../Component"
import { TableWrapper } from "../Component/InventoryCards/InvCardContent";

const Home = props => {

    const [inventory, setInventory] = useState([]);
    const [totals, setTotals] = useState([]);
    const { user, getAccessTokenSilently } = useAuth0();
    const BetaRole = user['http://user.com/roles'][0] === 'Beta User'
    const [betaUser, setBetaUser] = useState(false)
    const [keyValid, setKeyValid] = useState(false)

    useEffect(() => {
        const getUserMetadata = async () => {
          const domain = "dev-ativ-05e.us.auth0.com";
    
            const token = await getAccessTokenSilently({
              audience: `https://${domain}/api/v2/`,
              scope: "read:current_user",
            });
            const userid = user.sub
            const tokmessage = {token, userid}
            const response = await fetch("/currInventory", {
              method: "POST",
              headers: {
                  "Content-Type": "application/json"
              },
              body: JSON.stringify(tokmessage)
              
            });
    
            if (response.ok) {
              const temp = await response.json()                            
              setInventory(temp.inventory)
              setTotals(temp.totals)
              console.log("response worked!")
            }
          }; 
        getUserMetadata();
      }, [getAccessTokenSilently, user]);
      
    useEffect(() => {
      const getUserMetadata = async () => {
        if (!BetaRole){
          const message = user.sub
          const response = await fetch("/checkForBeta", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(message)
          });
  
          if (response.ok) {
            const isBeta = await response.json();
            setBetaUser(isBeta.isBeta)
          }
        }
        }; 
      getUserMetadata();
    }, [user,BetaRole]);

  // useEffect(() => {
  //   const getKeyData = async () => {
  //     const domain = "dev-ativ-05e.us.auth0.com";
  //     const token = await getAccessTokenSilently({
  //       audience: `https://${domain}/api/v2/`,
  //       scope: "read:current_user",
  //     });
  //     const userid = user.sub;
  //     const bearerT = "Bearer " + token;
  //     const userURL =
  //       "https://dev-ativ-05e.us.auth0.com/api/v2/users/" + userid;

  //     const response = await fetch(userURL, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: bearerT,
  //       },
  //     });

  //     if (response.ok) {
  //       const userInfo = await response.json();
  //       if (typeof userInfo.user_metadata.key !== "undefined") {
  //         const keyExist = userInfo.user_metadata.key;
  //         const bTok = process.env.REACT_APP_WHOP_BEARER;
  //         const bearerT = `Bearer ${bTok}`
  //         const URL = `https://api.whop.io/api/v1/licenses/${keyExist}`
  //         const response2 = await fetch(URL, {
  //             method: "GET",
  //             headers: {
  //                 "Content-Type": "application/json",
  //                 "Authorization": bearerT,
  //             },
  //         })
  //         if (response2.ok) {
  //           const keyInfo = await response2.json();
  //           if (typeof keyInfo.plan !== "undefined") {
  //             if (keyInfo.plan.id === 1422 | keyInfo.plan.id === 1424 | keyInfo.plan.id === 1794 | keyInfo.plan.id === 2004){
  //               if (user.sub.includes(keyInfo.discord.discord_account_id)){
  //                 console.log('valid plan and matching discord')
  //                 setKeyValid(true)
  //               }
  //             }
  //           }
  //         } else {
  //           console.log('invalid key')
  //         }
  //       }
  //     }

  //     // const response = await fetch(userURL, {
  //     //     method: "GET",
  //     //     headers: {
  //     //         "Content-Type": "application/json",
  //     //         "Authorization": bearerT,
  //     //     },
  //     // });


  //     }; 
  //   getKeyData();
  // }, );


    const useStyles = makeStyles((theme) => ({
      root: {
        display: 'flex',
      },
      // toolbar: {
      //   display: 'flex',
      //   alignItems: 'center',
      //   justifyContent: 'flex-end',
      //   padding: theme.spacing(0, 1),
      //   // necessary for content to be below app bar
      //   ...theme.mixins.toolbar,
      // },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
      },
    }));
    
    const classes = useStyles();

    const { history } = props;

    const display =  (betaUser | BetaRole ) ?             
    <Container maxWidth="lg" className={classes.container}>
    {/* <Grid container spacing={2} justifyContent ="flex-start" className={styles.title}> */}
      <Typography gutterBottom={true} variant="h5">Inventory</Typography>
    {/* </Grid> */}
    <InventoryCards totals={totals}/>
    <TableWrapper>
      <InventoryOverview  inventory = {inventory} onChangeI={inventory  => {setInventory(currentInventory => inventory)}}/>
    </TableWrapper>
    </Container> :
    <Typography></Typography>

    return (
    <div className={classes.root}>
      {/* <CustomDrawer props={props}/> */}
        <main className={classes.content}>
            <div className={classes.toolbar} />
              {display}
        </main>
    </div>
    )
  };
  
  export default Home;
