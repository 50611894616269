import React, {useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import TablePagination from '@material-ui/core/TablePagination';
import { Grid } from '@material-ui/core';
import { withStyles,makeStyles } from "@material-ui/core/styles";
import StickyTable from "react-sticky-table-thead"
import { TableSortLabel } from '@material-ui/core';
import { TableContainer } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { useAuth0 } from "@auth0/auth0-react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import ClearIcon from '@mui/icons-material/Clear';
import Typography from '@material-ui/core/Typography';


const StyledTableCell = withStyles((theme) => ({
  root:{
    whiteSpace: "nowrap"
  }  

  
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    maxHeight:10,
    height: 5, 
    padding:0,

    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.action.hover,
      backgroundColor:theme.palette.background.paper,

    },
  },
}))(TableRow);

const headCells = [
  { id: 'date', label: 'Date', type: 'date'},
  { id: 'item', label: 'Item', type: 'string'},
  { id: 'size', label: 'Size', type: 'string'},
  { id: 'price', label: 'Price', type: 'string', numeric: true},
  { id: 'status', label: 'Status', type: 'string'},
  { id: 'tracking', label: 'Tracking', type: 'string'},
  { id: 'site', label: 'Site', type: 'string'},
  { id: 'email', label: 'Email', type: 'string'},
  { id: 'delete', label: 'Delete?', type: 'string'},
]

const handleClick = (tNumber) => {
  if (tNumber !== '' && tNumber !=='None') {
  return (event) => {
    window.open(tNumber, "_blank")
  }}
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 300,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  row: {
    fontSize: 14,
  },
  title: {
    marginLeft: 10,
    marginTop: 8,
    marginBottom: 10
  },
  row2:{
    fontSize: 14,
    color: theme.palette.colors.hyperlink,
    textDecorationLine: 'underline',
  }
}));

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'date'){
    const dateA = new Date(a.date)
    const dateB = new Date(b.date)
    if (dateB < dateA) {
      return -1;
    }
    if (dateB > dateA) {
      return 1;
    }
    return 0;
  } else{
    
    if (orderBy === 'sneaker' || orderBy === 'site' || orderBy === 'status') {
      if (b[orderBy].toUpperCase() < a[orderBy].toUpperCase()) {
        return -1;
      }
      if (b[orderBy].toUpperCase() > a[orderBy].toUpperCase()) {
        return 1;
      }
      return 0;

    } else if (orderBy === 'price' ) {
      if (parseFloat(b[orderBy].replace(/,/g,'').replace("$",'')) < parseFloat(a[orderBy].replace(/,/g,'').replace("$",''))) {
        // console.log(parseFloat(b[orderBy].replace(/,/g,'').replace("$",'')))
        return -1;
      }
      if (parseFloat(b[orderBy].replace(/,/g,'').replace("$",'')) > parseFloat(a[orderBy].replace(/,/g,'').replace("$",''))) {
        return 1;
      }
      return 0;
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
  }
}

}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  
  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
}

export default function Orders( {onChangeD, onChangeO, orders}) {
  const classes = useStyles();
  const [order, setOrder] = useState()
  const [orderBy, setOrderBy] = useState()
  const [openStatus, setOpenStatus] = useState(false);
  const [openItem, setOpenItem] = useState(false);
  const [itemNum, setItemNum] = useState('');
  const { user, getAccessTokenSilently } = useAuth0();
  const domain = "dev-ativ-05e.us.auth0.com";
  const [status, setStatus] = useState('');
  const [statusId, setStatusId] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleClose = () => {
    setOpenStatus(false);};
  const handleClickOpen = () => {
    setOpenStatus(true); };

  const deleteItemOpen = () => {
    setOpenItem(true); };
  const deleteItemClose = () => {
    setOpenItem(false); };

  const handleStatusChange = (id, orderStatus) => {
    return (event) => {
      setStatus(orderStatus)
      setStatusId(id)
      handleClickOpen()
    }
  }
  
  const handleDelete = (orderNum) => {
    return (event) => {
      deleteItemOpen()
      setItemNum(orderNum)
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const emptyRows =
  page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders.length) : 0;
  
  const ellipsisStyle = {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '350px',
    maxWidth: '350px' }

  return (
  <div>    
    <Dialog open={openStatus} onClose={handleClose} aria-labelledby="form3">
  <DialogTitle id="form3">Change Item Status</DialogTitle>
  <DialogContent>
    <DialogContentText>
      Select an option to change the status of an order
    </DialogContentText>
    <FormControl component="fieldset">
      <RadioGroup row aria-label="status" name="row-radio-buttons-group" value={status} onChange={handleChange}>
        <FormControlLabel value="Confirmed" control={<Radio sx={{
            color: "#F1E0C5",
            '&.Mui-checked': {
              color: "#F1E0C5",
            },
          }}/>} label="Confirmed" />
        <FormControlLabel value="Shipped" control={<Radio sx={{
            color: "#F1E0C5",
            '&.Mui-checked': {
              color: "#F1E0C5",
            },
          }}/>} label="Shipped" />
        <FormControlLabel value="Cancelled" control={<Radio sx={{
              color: "#F1E0C5",
              '&.Mui-checked': {
                color: "#F1E0C5",
              },
            }}/>} label="Cancelled" />
      </RadioGroup>
    </FormControl>
  </DialogContent>
  <DialogActions>
  <Button onClick={handleClose} color="primary">
      Cancel
  </Button>
  <Button style={{backgroundColor: "#DC676C"}} 
      onClick ={async () => {  
          const token = await getAccessTokenSilently({
              audience: `https://${domain}/api/v2/`,
              scope:'read:current_user',
          });
          const userid = user.sub
          const message = {token, userid, statusId, status}

          const response = await fetch("/updateStatus", {
              method: "POST",
              headers: {
                  "Content-Type": "application/json"
              },
              body: JSON.stringify(message)
          });
          if (response.ok) {
              const message2 = {token, userid, site:'All Sites', timeStart:'Last Week', timeEnd:'Last Week', timeLength:'All Time'}

              const response2 = await fetch("/getQuery", {
                  method: "POST",
                  headers: {
                      "Content-Type": "application/json"
                  },
                  body: JSON.stringify(message2)
              });
              
              if (response2.ok) {
                  const newInfo = await response2.json();
                  const newOrders = newInfo.allInfo.allOrders
                  const newQueries = newInfo.allInfo.queries
                  onChangeO(newOrders)
                  onChangeD(newQueries)
                  console.log("response worked!");

              }
          }
          handleClose()

      }} >
      Change Status
  </Button>
  </DialogActions>
  </Dialog>

  <Dialog open={openItem} onClose={deleteItemClose} aria-labelledby="form3">
    <DialogTitle id="form3">Delete Item</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to delete this item from the Order Hub?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
    <Button onClick={deleteItemClose} color="primary">
        Cancel
    </Button>
    <Button style={{backgroundColor: "#DC676C"}} 
        onClick ={async () => {  
            const token = await getAccessTokenSilently({
                audience: `https://${domain}/api/v2/`,
                scope:'read:current_user',
            });
            const userid = user.sub
            const message = {token, userid, itemNum}

            const response = await fetch("/deleteOrderHub", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(message)
            });
            if (response.ok) {
                const message2 = {token, userid, site:'All Sites', timeStart:'Last Week', timeEnd:'Last Week', timeLength:'All Time'}

                const response2 = await fetch("/getQuery", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(message2)
                });
                
                if (response2.ok) {
                    const newInfo = await response2.json();
                    const newOrders = newInfo.allInfo.allOrders
                    const newQueries = newInfo.allInfo.queries
                    onChangeO(newOrders)
                    onChangeD(newQueries)
                    console.log("response worked!");

                }
            }
            deleteItemClose()

        }} >
        Change Status
    </Button>
    </DialogActions>
  </Dialog>
    {/* <React.Fragment> */}
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Recent Orders
        </Typography>
      {/* <StickyTable> */}
      {/* <TableContainer style={{ width: 1300 }}> */}
      <TableContainer >

      <Table size="small" aria-label="a dense table" className={classes.table}>
        {/* <TblHead />  */}
        <EnhancedTableHead
              classes = {classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={orders.length}
            />
        <TableBody>
          {stableSort(orders, getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((orders) => {
          return(
            <StyledTableRow key={orders.id}>
              <StyledTableCell>{orders.date}</StyledTableCell>
              <TableCell style={ellipsisStyle} className={(orders.orderInfo === 'None') ? classes.row : classes.row2} onClick={(orders.orderInfo === 'None') ? null : handleClick(orders.tNumber)}>{orders.sneaker}</TableCell>
              <StyledTableCell>{orders.size}</StyledTableCell>
              <StyledTableCell align="right">{orders.price}</StyledTableCell>
              <StyledTableCell onClick={handleStatusChange(orders.orderNum, orders.status)}>{orders.status}</StyledTableCell>
              {orders.tNumber === 'None'}
              <TableCell className={(orders.track === 'Cancelled' || orders.track === 'None' || orders.track === 'Ready For Pickup') ? classes.row : classes.row2} onClick={handleClick(orders.tNumber)}>{orders.track}</TableCell>
              <StyledTableCell>{orders.site}</StyledTableCell>
              <StyledTableCell>{orders.email}</StyledTableCell>
              <StyledTableCell onClick={handleDelete(orders.orderNum)}><ClearIcon style={{fill: "#DC676C"}}/></StyledTableCell>

            </StyledTableRow>
          );
          })}


          {emptyRows > 0 && (
            <TableRow style={{ height: 33 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      </TableContainer>
      <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={orders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

        {/* </StickyTable> */}
    {/* </React.Fragment> */}

  </div>
  
  );
}

//export default Orders;