import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import {
  Chart,
  Series,
  ArgumentAxis,
  ValueAxis,
  Title,
  Legend,
  Label,
  Tick,
  Font,
  CommonAxisSettings,
  Format,
  Tooltip
} from 'devextreme-react/chart';

import { Grid } from '@material-ui/core';

export default function ProfitCharts({results}) {
    return (
      <Paper>
        <Chart
          id="chart"
          dataSource={results}
          //title="Profits Overview"
        >
          <Title text="Profit Overview">
            <Font color="white" />
          </Title>

          <CommonAxisSettings 
            Grid={false}
            argumentField="date"
            type="bar"
            hoverMode="allArgumentPoints"
            selectionMode="allArgumentPoints"
          >
              
              <Label visible={true}>
                <Format type="fixedPoint" precision={0} />
              </Label>

          </CommonAxisSettings>

          <Series argumentField="date" valueField="profit" type="bar">
            {/*<Label visible={true} />*/}
            
          </Series>
          <Tooltip enabled={true}/>
          
          <ValueAxis>
            <Grid visible = {false}/>
          </ValueAxis>
          <ArgumentAxis Grid={false}>
            <Grid visible = {false}/>
            <Tick visible={false}/>
            <Label
              wordWrap="none"
              rotationAngle={45}
              staggeringSpacing = {100}
              displayMode = {"rotate"}
              overlappingBehavior={"hide"}
            />
          </ArgumentAxis>
          <Grid visible = {false}/>
          <Legend visible={false} />
        </Chart>
      </Paper>

    );
  }
