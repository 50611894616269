import React, {useEffect, useState} from 'react';
import { TextField} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useAuth0 } from "@auth0/auth0-react";
import { MiscWrapper, ButtWrapper } from './MiscSettingsContainer';
import styles from './MiscSettings.module.css'
import { CSVLink } from "react-csv";
import { useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const MiscSettings = ({allowForward,currWebhook}) => {
    const csvLink = useRef()
    const domain = "dev-ativ-05e.us.auth0.com";
    const [webhook, setWebhook] = useState(currWebhook);
    const { user, getAccessTokenSilently } = useAuth0();
    const [inputValue, setInputValue] = useState(currWebhook);
    const [exportData, setExportData] = useState([])
    const [exportFile, setExportFile] = useState("")
    const [open, setOpen] = useState(false);

    if (allowForward === undefined){
        allowForward = false   
    }

    useEffect(() => { setWebhook(currWebhook)}, [currWebhook] )
    useEffect(() => { setInputValue(currWebhook)}, [currWebhook] )
    
    // Input Field handler
    const handleUserInput = (e) => {
        setInputValue(e.target.value);
        setWebhook(e.target.value)
    };

    // Reset Input Field handler
    const resetInputField = () => {
        setInputValue("");
        setWebhook("")
    }; 


    const handleClose = () => {
        setOpen(false);};
    const handleClickOpen = () => {
        setOpen(true); };


    return(
    <MiscWrapper>
        <TextField label = "Discord Webhook" className={styles.menu} variant="standard"
            InputLabelProps={{ shrink: true }}
            value = {inputValue}
            onChange={handleUserInput}>
        </TextField>
        <ButtWrapper>
        <Button variant="contained" color="secondary" className={styles.butt2}
                onClick ={async () => {  
                    const token = await getAccessTokenSilently({
                        audience: `https://${domain}/api/v2/`,
                        scope:'read:current_user',
                    });
                    const userid = user.sub
                    const bearerT = 'Bearer ' + token
                    const userURL = `https://dev-ativ-05e.us.auth0.com/api/v2/users/${userid}`
                    const message = '{"user_metadata": {"webhook": "' + webhook + '"}}'
                    const response = await fetch(userURL, {
                        method: "PATCH",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": bearerT,
                        },
                        body: message
                    });
                    if (response.ok) {
                        console.log("response worked!");
                    }
                }}>
                Add
        </Button>
        <Button variant="contained" color="secondary" className={styles.butt2}
                onClick ={async () => {  
                    const token = await getAccessTokenSilently({
                        audience: `https://${domain}/api/v2/`,
                        scope:'read:current_user',
                    });
                    const userid = user.sub
                    const bearerT = 'Bearer ' + token
                    const userURL = `https://dev-ativ-05e.us.auth0.com/api/v2/users/${userid}`
                    const message = '{"user_metadata": {"webhook": ""}}'
                    const response = await fetch(userURL, {
                        method: "PATCH",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": bearerT,
                        },
                        body: message
                    });
                    if (response.ok) {
                        resetInputField()
                        console.log("response worked!");
                    }
                }}>
                Delete
        </Button>
        <Button variant="contained" color="secondary" className={styles.butt2}
                onClick ={async () => {  
                    const token = await getAccessTokenSilently({
                        audience: `https://${domain}/api/v2/`,
                        scope:'read:current_user',
                    });
                    const message = {webhook}
                    const response = await fetch('/testWeb', {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(message)
                    });
                    if (response.ok) {
                        console.log("response worked!");
                    }

                    const userid = user.sub
                    const bearerT = 'Bearer ' + token
                    const userURL = "https://dev-ativ-05e.us.auth0.com/api/v2/users/" +userid
                    const message1 = '{"user_metadata": {"webhook": "' + webhook + '"}}'
                    const response1 = await fetch(userURL, {
                        method: "PATCH",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": bearerT,
                        },
                        body: message1
                    });
                    if (response1.ok) {
                        console.log("Webhook sent");
                    }
                }}>
                Test
        </Button>
        </ButtWrapper>
        <div></div>
        <div></div>
        <div></div>
        <Button variant="contained" color="secondary" className={styles.butt3}
                onClick ={async () => { 
                    handleClickOpen(true)
                    // const token = await getAccessTokenSilently({
                    //     audience: `https://${domain}/api/v2/`,
                    //     scope:'read:current_user',
                    // });
                    // const userid = user.sub
                    // const bearerT = 'Bearer ' + token
                    // const userURL = "https://dev-ativ-05e.us.auth0.com/api/v2/users/" +userid
                    // const response = await fetch(userURL, {
                    //     method: "GET",
                    //     headers: {
                    //         "Content-Type": "application/json",
                    //         "Authorization": bearerT,
                    //     },
                    // });
                    // if (response.ok){
                    //     const userInfo = await response.json();
                    //     const orders = userInfo.user_metadata.orders
                    //     setExportData(orders)
                    //     setExportFile('auttr_scraped_orders.csv')
                    //     csvLink.current.link.click()

                    //     // csvLink.current.link.click()
                    // }
                }}>Export Scraped Orders</Button>
            <Button variant="contained" color="secondary" className={styles.butt3}
                onClick ={async () => {  
                    const token = await getAccessTokenSilently({
                        audience: `https://${domain}/api/v2/`,
                        scope:'read:current_user',
                    });
                    const userid = user.sub
                    const bearerT = 'Bearer ' + token
                    const userURL = "https://dev-ativ-05e.us.auth0.com/api/v2/users/" +userid
                    const response = await fetch(userURL, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": bearerT,
                        },
                    });
                    if (response.ok){
                        const userInfo = await response.json();
                        if (userInfo.user_metadata.manualOrders){
                            const orders = userInfo.user_metadata.manualOrders
                            setExportData(orders)
                            setExportFile('auttr_manual_orders.csv')
                            csvLink.current.link.click()
                        }

                    }
                }}>Export Manual Orders</Button>
            <CSVLink
                data={exportData}
                filename={exportFile}
                className='hidden'
                ref={csvLink}
                target='_blank'
            />

        <Dialog open={open} onClose={handleClose} aria-labelledby="form3">
            <DialogTitle id="form3">Export orders</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Do you want to export orders with or without cancellations?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="primary">
                Cancel
            </Button>
            <Button color="primary"
                onClick ={async () => {  
                    const token = await getAccessTokenSilently({
                        audience: `https://${domain}/api/v2/`,
                        scope:'read:current_user',
                    });
                    const userid = user.sub
                    const bearerT = 'Bearer ' + token
                    const userURL = "https://dev-ativ-05e.us.auth0.com/api/v2/users/" +userid
                    const response = await fetch(userURL, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": bearerT,
                        },
                    });
                    if (response.ok){
                        const userInfo = await response.json();
                        const orders = userInfo.user_metadata.orders
                        setExportData(orders)
                        setExportFile('auttr_scraped_orders.csv')
                        csvLink.current.link.click()
                        handleClose()
                    }
                }} >
                Export With Cancels
            </Button>
            <Button color="primary"
                onClick ={async () => {  
                    const token = await getAccessTokenSilently({
                        audience: `https://${domain}/api/v2/`,
                        scope:'read:current_user',
                    });
                    const userid = user.sub
                    const bearerT = 'Bearer ' + token
                    const userURL = "https://dev-ativ-05e.us.auth0.com/api/v2/users/" +userid
                    const message = {token, userid}
                    const response = await fetch("/exportnocancels", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(message)
                    });
                    if (response.ok){
                        const userInfo = await response.json();
                        const orders = userInfo.orders
                        setExportData(orders)
                        setExportFile('auttr_scraped_orders_no_cancels.csv')
                        csvLink.current.link.click()
                        handleClose()
                    }
                }} >
                Export Without Cancels
            </Button>
            </DialogActions>
        </Dialog>
    </MiscWrapper>

 
        
      )
}