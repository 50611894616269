import styled from "styled-components";

export const MiscWrapper = styled.div`
  max-width: 1000px;
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 25px;
  justify-content: start;
`;

export const ButtWrapper = styled.div`
  max-width: 1000px;
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 25px;
  justify-content: start;

  //padding: 0 50px;

  /* @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  } */
`;
