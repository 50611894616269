import React from 'react';
import { Card, CardContent, Typography, Grid } from '@material-ui/core';
import CountUp from 'react-countup';
import { withStyles } from "@material-ui/core/styles";
import styles from './InventorySummary.module.css'
import Divider from '@material-ui/core/Divider';

const RedTextTypography = withStyles({
    root: {
      color: "Red"
    }
  })(Typography);

const GreenTextTypography = withStyles({
root: {
    color: "Green"
}
})(Typography);

export const InventorySummary = ({totals}) => {
    console.log(totals)
    if (!totals.purchases) {
        totals.purchases = 0;
        totals.sales = 0;
        totals.expenses = 0;
        totals.profit = 0;
        totals.retail = 0;
        totals.inventory = 0;
        totals.items = 0;
    }
    return(
        //<div className={StylesProvider.container}>
            <Grid container spacing={2} justify ="space-between">
                <Grid item component={Card} xs={12} className={styles.card}>
                    <CardContent>
                        <Grid container spacing = {2} >
                            <Typography variant = "h6">
                                <CountUp start = {0} end = {totals.items} duration = {2.5} separator=","/>
                            </Typography>
                            <Typography className={styles.total} gutterBottom={true} justify="space-between" color="textPrimary" variant = "h6">Total Items</Typography>
                        </Grid>
                        <Typography className={styles.title} gutterBottom={true} justify="space-between" color="textSecondary" variant = "body2">Total Purchases</Typography>


                        <Typography variant="h5" gutterBottom={true}>
                                <CountUp start = {0} end = {totals.purchases} prefix = "$" duration = {2.5} separator=","/>
                        </Typography>

                        <Typography className={styles.title} gutterBottom={true} justify="space-between" color="textSecondary" variant = "body2">Total Sales</Typography>

                        <Typography variant="h5" gutterBottom={true}>
                                <CountUp start = {0} end = {totals.sales} prefix = "$" duration = {2.5} separator=","/>
                        </Typography>

                        <Typography className={styles.title} gutterBottom={true} justify="space-between" color="textSecondary" variant = "body2">Total Expenses</Typography>

                        <RedTextTypography variant="h5" gutterBottom={true}>
                                <CountUp start = {0} end = {totals.expenses} prefix = "$" duration = {2.5} separator=","/>
                        </RedTextTypography>

                        <Typography className={styles.title} gutterBottom={true} justify="space-between" color="textSecondary" variant = "body2">Total Profit</Typography>

                        <GreenTextTypography variant="h5" gutterBottom={true}>
                                <CountUp start = {0} end = {totals.profit} prefix = "$" duration = {2.5} separator=","/>
                        </GreenTextTypography>

                        <Divider variant="middle" />


                        <Grid container spacing = {2} >
                            <Typography variant = "h6" className={styles.title2}> 
                                <CountUp start = {0} end = {totals.inventory} duration = {2.5} separator=","/>
                            </Typography>
                            <Typography className={styles.total2} gutterBottom={true} justify="space-between" color="textPrimary" variant = "h6">Items in Inventory</Typography>
                        </Grid>

                        <Typography className={styles.title} gutterBottom={true} justify="space-between" color="textSecondary" variant = "body2">Retail Value</Typography>

                        <Typography variant="h5" gutterBottom={true}>
                                <CountUp start = {0} end = {totals.retail} prefix = "$" duration = {2.5} separator=","/>
                        </Typography>




                    </CardContent>
                </Grid>
            </Grid>
        //</div>
    )
}

export default InventorySummary;