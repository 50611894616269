import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import CountUp from 'react-countup';
import ShoppingCartTwoToneIcon from '@material-ui/icons/ShoppingCartTwoTone';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import LocalShippingTwoToneIcon from '@material-ui/icons/LocalShippingTwoTone';
import SellIcon from '@mui/icons-material/Sell';
import EqualizerTwoToneIcon from '@material-ui/icons/EqualizerTwoTone';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { CardWrapper, InventoryCard, VLine,StyledIcon, CardInfo,CardChartWrapper, ChartWrapper } from './InvCardContent';
import Transactions from "./Transactions";
import Paper from "@material-ui/core/Paper";
import Chart from 'react-apexcharts'
import { withTheme } from "@material-ui/core/styles";
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';

export const InventoryCards = ({totals}) => {
    if (!totals.items) {
        totals.items = 0;
        totals.value = 0;
        totals.average = 0;
        totals.monthly = 0;
    }

    const theme = useTheme();
    const chartOptions ={
            series: [{
              name: 'Items',
              data: totals.chartY1
            }, {
              name: 'Value',
              data: totals.chartY2
            }],

        options: {
            color: ['#2980b9','#6ab04c'],
            theme: { mode: theme.palette.type},
            chart: {
                background: 'transparent',
                foreColor: theme.palette.text.primary
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                categories: totals.chartX,
                tickAmount: 6,
                labels: {
                    rotate: -0,
                }
            },
            // xaxis: {
            //     tickAmount: 6,
            //   },
            yaxis: [
                {
                  decimalsInFloat: 0,
                  axisTicks: {
                    show: true,
                  },
                  axisBorder: {
                    show: true,
                    // color: '#008FFB'
                  },
                  labels: {
                    style: {
                    //   colors: '#008FFB',
                    }
                  },
                  title: {
                    text: "Items in Inventory",
                    style: {
                    //   color: '#008FFB',
                    }
                  },
                  tooltip: {
                    enabled: true
                  }
                },
                {
                  seriesName: 'Value',
                  decimalsInFloat: 0,
                  opposite: true,
                  axisTicks: {
                    show: true,
                  },
                //   axisBorder: {
                //     show: true,
                //     color: '#00E396'
                //   },
                  labels: {
                    formatter: function (value) {
                        return "$" +value;
                      },
                    style: {
                    //   colors: '#00E396',
                    }
                  },
                  title: {
                    text: "Inventory Value",
                    style: {
                    //   color: '#00E396',
                    }
                  },
                },
              ],
            legend: {
                position: 'top'
            },
            grid: {
                show: false
            }
        }
    }

    return(
        <CardChartWrapper>
        <CardWrapper>
            <InventoryCard>
                <StyledIcon>
                    <ShoppingCartTwoToneIcon style={{ color: "#8b8e96",fontSize: 35 }}/>
                </StyledIcon>
                <VLine/>
                <CardInfo>
                    <Typography variant="h4" align = 'center'>
                        <CountUp start = {0} end = {totals.items} duration = {2.5} separator=","/>
                    </Typography> 
                    
                    <Typography color="textSecondary" variant = "body2" align='center'>Items in Inventory</Typography>
                </CardInfo>
            </InventoryCard>
            <InventoryCard>
                <StyledIcon>
                    <AccountBalanceTwoToneIcon style={{ color: "#8b8e96",fontSize: 35 }}/>
                </StyledIcon>
                <VLine/>
                <CardInfo>
                    <Typography variant="h4" align = 'center'>
                        <CountUp start = {0} end = {totals.value} duration = {2.5} prefix = "$" separator=","/>
                    </Typography> 
                    <Typography color="textSecondary" variant = "body2" align='center'>Inventory Value</Typography>
                </CardInfo>
            </InventoryCard>
            <InventoryCard>
                <StyledIcon>
                    <SellIcon style={{ color: "#8b8e96",fontSize: 35 }}/>
                </StyledIcon>
                <VLine/>
                <CardInfo>
                    <Typography variant="h4"  align = 'center'>
                        <CountUp start = {0} end = {totals.average} duration = {2.5} prefix = "$" separator=","/>
                    </Typography> 
                    <Typography color="textSecondary" variant = "body2" align='center'>Average Price</Typography>
                </CardInfo>
            </InventoryCard>
            <InventoryCard>
                <StyledIcon>
                    <LocalShippingTwoToneIcon style={{ color: "#8b8e96",fontSize: 35 }}/>
                </StyledIcon>
                <VLine/>
                <CardInfo>
                    <Typography variant="h4" align = 'center'>
                        <CountUp start = {0} end = {totals.monthly} duration = {2.5} separator=","/>
                    </Typography> 
                    <Typography color="textSecondary" variant = "body2" align='center'>Items This Month</Typography>
                </CardInfo>
            </InventoryCard>
        </CardWrapper>
        <ChartWrapper>
            <Chart
                options={chartOptions.options}
                series={chartOptions.series}
                type='area'
                height='100%'
            />
        </ChartWrapper>

        </CardChartWrapper>
    )
}

export default InventoryCards;