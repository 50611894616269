import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { OverviewPicker, ProfitCharts,InventorySummary } from '../Component';
import { Grid , Typography } from '@material-ui/core';
import styles from '../App.module.css';
import { useAuth0 } from "@auth0/auth0-react";
import CustomDrawer from "../Component/CustomDrawer"

const Overview = props => {
    const [results, setResults] = useState([]);
    const [totals, setTotals] = useState([]);
    const { user, getAccessTokenSilently } = useAuth0();
    const BetaRole = user['http://user.com/roles'][0] === 'Beta User'
    const [betaUser, setBetaUser] = useState(false)


    useEffect(() => {
      const getUserMetadata = async () => {
        const domain = "dev-ativ-05e.us.auth0.com";
  
          const token = await getAccessTokenSilently({
            audience: `https://${domain}/api/v2/`,
            scope: "read:current_user",
          });
          const userid = user.sub
          const tokmessage = {token, userid, timeStart:"Last Week", timeEnd:"Last Week", viewBy:'Month', timeLength: "All Time"}
          const response = await fetch("/chartResult", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(tokmessage)
            
          });
  
          if (response.ok) {
            const temp = await response.json()                            
            setResults(temp.results)
            setTotals(temp.totals)
            console.log("response worked!")
          }
        }; 
      getUserMetadata();
    }, [user,getAccessTokenSilently]);

    useEffect(() => {
      const getUserMetadata = async () => {
        if (!BetaRole){
          const message = user.sub
          const response = await fetch("/checkForBeta", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(message)
          });
  
          if (response.ok) {
            const isBeta = await response.json();
            setBetaUser(isBeta.isBeta)
          }
        }
        }; 
      getUserMetadata();
    }, [user,BetaRole]);
  
    const useStyles = makeStyles((theme) => ({
      root: {
        display: 'flex',
      },
      toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
      },
    }));
    
    const classes = useStyles();    
    const { history } = props;

      const display = (betaUser | BetaRole) ?             
      <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={2} justify ="flex-start" className={styles.title}>
        <Typography gutterBottom variant="h5">Overview</Typography>
      </Grid>
      <OverviewPicker onChangeD={results  => {setResults(currentResults => results)}} />


      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={9}>
          <ProfitCharts results = {results}/>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <InventorySummary totals = {totals}/>
        </Grid>
      </Grid>

    </Container> :
    <Typography></Typography>

  return (
    <div className={classes.root}>
    {/* <CustomDrawer props={props}/> */}

    <main className={classes.content}>
        <div className={classes.toolbar} />
          {display}
    </main>
    </div>
  )


}; 

export default Overview;
