export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "An autonomous reselling experience",
  headline:
    "Take the work out of organizing, managing, and tracking your assets",
  description:
    "Our goal at auttr is to eliminate the unnecessary work associated with reselling by automatically organizing and displaying your orders, sales, and profits",
  buttonLabel: "Get Started Here",
  imgStart: false,
  img: require("../../images/svg-2.svg").default,
  alt: "Track",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjTwo = {
  id: "discover",
  lightBg: true,
  lightText: false,
  lightTextDesc: true,
  topLine: "Hassle free management",
  headline: "Scrape your emails with one click",
  description:
    "auttr automatically finds all of your order confirmation, shipping, and cancellation emails and organizes your inventory so you don't have to",
  buttonLabel: "Get Started Here",
  imgStart: true,
  img: require("../../images/svg-1.svg").default,
  alt: "Email",
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjThree = {
  id: "signup",
  lightBg: true,
  lightText: false,
  lightTextDesc: true,
  topLine: "Get started today",
  headline: "We are now available for public purchase",
  description:
    "We offer multiple subscription options that cater to your needs. No matter how large your operation is, auttr can help improve your productivity!",
  buttonLabel: "Get Started Here",
  imgStart: false,
  img: require("../../images/svg-3.svg").default,
  alt: "package",
  dark: false,
  primary: false,
  darkText: true,
};
