import "./App.css";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Home from "./pages";
import EmailHub from "./pages/OrderHub";
import Overview from "./pages/Overview";
import Settings from "./pages/Settings";
import Inventory from "./pages/Inventory";
import Expenses from "./pages/Expenses";
import Sales from "./pages/Sales";
import { ToastProvider } from "react-toast-notifications";
import terms from "./pages/terms";
import ProtectedRoute from "./auth/protected-route";
import RouteChangeTracker from "./Component/RouteChangeTracker";
import ReactGA from "react-ga";
import { keepTheme } from "./Component/theme";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core";
import CustomDrawer from "./Component/CustomDrawer";
import Drawer from "./Component/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";

function App() {
  useEffect(() => {
    keepTheme();
  });
  let tempTheme = localStorage.getItem("theme");
  const [toggleDark, settoggleDark] = useState(tempTheme === "theme-dark");

  const theme = createTheme({
    palette: {
      type: toggleDark ? "dark" : "light",

      background: {
        default: toggleDark ? "#101725" : "#A4BAC7",
        paper: toggleDark ? "#192841" : "#ffffff",
      },
      primary: {
        main: toggleDark ? "#F1E0C5" : "#101725",
      },
      secondary: {
        main: toggleDark ? "#F1E0C5" : "#ffffff",
      },
      colors: {
        drawer: toggleDark ? "#101725" : "#ffffff",
        hyperlink: toggleDark ? "#8AAAE4" : "#0645AD",
        logout: toggleDark ? "#192841" : "#A4BAC7",
      },
    },
  });
  ReactGA.initialize("UA-215905076-1");
  ReactGA.pageview("test-init-pageview");

  const location = useLocation();
  const useStyles = makeStyles((theme) => ({
    root: {
      display: location.pathname === "/" ? null : "flex",
    },
    toolbar: {
      display: location.pathname === "/" ? null : "flex",
      alignItems: location.pathname === "/" ? null : "center",
      justifyContent: location.pathname === "/" ? null : "flex-end",
      padding: location.pathname === "/" ? null : theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: location.pathname === "/" ? null : 1,
      padding: location.pathname === "/" ? null : theme.spacing(3),
    },
  }));

  const classes = useStyles();

  function isAuthRoute(pathname) {
    return pathname === "/";
  }

  const display =
    location.pathname === "/" ? null : (
      <CustomDrawer toggleDark={toggleDark} settoggleDark={settoggleDark} />
    );
  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <RouteChangeTracker />
        {display}
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <ToastProvider placement="bottom-right" autoDismissTimeout={20000}>
            <Switch>
              <Route
                path="/apply"
                component={() => {
                  window.location = "https://whop.com/products/auttr/home";
                  return null;
                }}
              />

              <Route exact path="/" component={Home} />
              <Route path="/Terms" component={terms} />

              <ProtectedRoute
                path="/Overview"
                component={(props) => <Overview {...props} />}
              />
              <ProtectedRoute
                path="/OrderHub"
                component={(props) => <EmailHub {...props} />}
              />
              <ProtectedRoute
                path="/Inventory"
                component={(props) => <Inventory {...props} />}
              />
              <ProtectedRoute
                path="/Sales"
                component={(props) => <Sales {...props} />}
              />

              <ProtectedRoute
                path="/Expenses"
                component={(props) => <Expenses {...props} />}
              />
              {/*<Route exact path="/Mining" component={props => <MiningRig {...props} />} />*/}
              {/*<Route path="/Sales" component={props => <Sales {...props} />} />*/}
              <ProtectedRoute path="/settings" component={Settings} />
            </Switch>
          </ToastProvider>
        </main>
      </ThemeProvider>
    </div>
  );
}
export default App;
